import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentMovementsService {

  private apiURL = 'api/movements/payments';

  constructor( private http: HttpClient ) { }

  get( year: number, fortnight: string, currentPage: number = 1, itemsPerPage: number = 10 ) {
    return this.http.get<any>(`${this.apiURL}/${year}/${fortnight}/${currentPage}/${itemsPerPage}`);
  }
}
