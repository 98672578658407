<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
            <li class="breadcrumb-item"><a [routerLink]="['/administration/lowstaffing', waytopay, start, end, searchValue]" routerLinkActive="router-link-active">Bajas</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3 class="title">
                        Pendientes <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <form [formGroup]="forma">
            <div class="row form-group">

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Año</label>
                        <select class="custom-select" formControlName="year">
                                                        <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                                                    </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Quincena</label>
                        <select class="custom-select" formControlName="fortnight">
                                                        <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                                                    </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 text-right">
                    <button [disabled]="exporting || jsonArray.length === 0" type="button" class="btn btn-outline-success" (click)="getExcel()">
                        <i [ngClass]="{ 'far fa-file-excel': !exporting, 'fas fa-sync-alt fa-spin': exporting }"></i>
                        <span *ngIf="!exporting"> Generar</span>
                        <span *ngIf="exporting" > Procesando...</span>
                    </button>
                </div>
            </div>
        </form>

        <div class="row text-right">
            <div class="col-12" >
                <input id="chkSelectAll" (change)="handlerSelectAll( $event.target.checked )" type="checkbox"  checked ><label for="chkSelectAll" > Seleccionar Todo</label>
            </div>
        </div>

        <div class="card items animated fadeIn fast" *ngIf="!loadingData && jsonArray.length > 0">
            <ul class="item-list striped">
                <li class="item item-list-header">
                    <div class="row">
                        <div class="item-col item-col-header item-col-header">
                            <div class="no-overflow">
                                <span>Nombre</span>
                            </div>
                        </div>
                        <div class="item-col item-col-header item-col-header">
                            <div>
                                <span>R.F.C.</span>
                            </div>
                        </div>
                        <div class="item-col item-col-header">
                            <div>
                                <span>Ahorros</span>
                            </div>
                        </div>
                        <div class="item-col item-col-header">
                            <div>
                                <span>Interes</span>
                            </div>
                        </div>
                        <div class="item-col item-col-header">
                            <div>
                                <span>Prestamos</span>
                            </div>
                        </div>
                        <div class="item-col item-col-header">
                            <div>
                                <span>Monto</span>
                            </div>
                        </div>
                        <div class="item-col item-col-header">
                            <div>
                                <span>Tipo</span>
                            </div>
                        </div>
                        <div class="item-col item-col-header fixed item-col-actions-dropdown"></div>
                    </div>
                </li>

                <li class="item" *ngFor="let item of jsonArray | angularPaginator: {itemsPerPage: this.itemsPerPage, currentPage: currentPage }; let i = index">
                    <div class="item-row">
                        <div class="item-col fixed pull-left item-col-title">
                            <div class="item-heading">Nombre</div>
                            <div>
                                <span>{{ item.name }}</span>
                            </div>
                        </div>

                        <div class="item-col item-col-item">
                            <div class="item-heading">R.F.C.</div>
                            <div>
                                <span>{{ item.rfc }}</span>
                            </div>
                        </div>

                        <div class="item-col item-col-item">
                            <div class="item-heading">Ahorro</div>
                            <div>
                                <span>{{ item.saving | currency }}</span>
                            </div>
                        </div>

                        <div class="item-col item-col-item">
                            <div class="item-heading">Interes</div>
                            <div>
                                <span>{{ item.interest | currency }}</span>
                            </div>
                        </div>

                        <div class="item-col item-col-item">
                            <div class="item-heading">Prestamos</div>
                            <div>
                                <span>{{ item.credit + item.creditInterest | currency }}</span>
                            </div>
                        </div>

                        <div class="item-col item-col-item">
                            <div class="item-heading">Monto</div>
                            <div>
                                <span>{{ item.amount | currency }}</span>
                            </div>
                        </div>

                        <div class="item-col fixed pull-left item-col-title">
                            <div class="item-heading">Tipo</div>
                            <div>
                                <span>{{ item.type }}</span>
                            </div>
                        </div>

                        <div class="item-col fixed item-col-actions-dropdown">
                            <div class="item-list-actions text-center">
                                <input (change)="handlerChecbox( $event.target.checked, item )" type="checkbox" [checked]="item.active" > |
                                <a class="required" (click)="showConfirm( item.id, item )" href="" data-toggle="modal">
                                    <i class="far fa-trash-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <app-angular-paginator *ngIf="!loadingData && jsonArray.length > 0" className="pagination-sm" forceEllipses="true" (pageChange)="currentPage = $event"></app-angular-paginator>

            <div class="item-list">
                <div class="item">
                    <div class="form-group text-center m-t-10">
                        <button [disabled]="working || !selectedItem" class="btn btn-outline-success" (click)="generateCheck()">
                                <i [ngClass]="{ 'far fa-save': !working, 'fas fa-sync-alt fa-spin': working }"></i>
                                <span *ngIf="!working"> Guardar</span>
                                <span *ngIf="working"> Guardando...</span>
                            </button>
                    </div>
                </div>
            </div>

            <div class="item-list" *ngIf="errorMessage">
                <div class="item">
                    <div class="form-group text-center m-t-10">
                        <app-error-message></app-error-message>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="row">
        <a class="btn btn-outline-info " [routerLink]="['/administration/lowstaffing', waytopay, start, end, searchValue] "><i class="fas fa-arrow-left "></i> Regresar</a>
    </div>

    <app-error-message *ngIf="errorMessage"></app-error-message>

</article>
