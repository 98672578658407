import { Component, OnInit, ViewChild } from '@angular/core';
import { OpenDirective } from '../../modals/open.directive';
import { TeacherService } from './services/teacher.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LowStaffInterfaces } from '../low-staffing/interfaces/low-staff.interfaces';
import { AccountService } from '../../account/login/services/account.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { LoanInterface } from '../loans/interfaces/loan.interface';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';
import { SelectOption } from '../../../../app/interfaces/select-option.interface';

@Component({
  selector: 'app-teacher-list',
  templateUrl: './teacher-list.component.html',
  styles: []
})
export class TeacherListComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];
  applications: ApplicationInterface[];
  @ViewChild(OpenDirective, { static: true }) adHost: OpenDirective;
  lowStaff: LowStaffInterfaces = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date,
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    active: true,
    teacherId: '00000000-0000-0000-0000-000000000000',
    checkNumber: 0,
    amountSavings: 0,
    amountInterests: 0,
    amountCredit: 0,
    amount: 0,
    status: 'Pendiente',
    type: 'Baja',
    movementDate : new Date(),
    interest5Percentage: 0,
    teacherStatusId: '',
    fortnigh: '',
    amountCreditInterest: 0,
    teacher: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      regionId: '00000000-0000-0000-0000-000000000000',
      rfc: 'N/A',
      name: 'N/A',
      street: 'N/A',
      number: '0',
      colony: 'N/A',
      zipCode: 0,
      locality: '',
      email: '',
      admission: '',
      birthdate: '1900-01-01',
      contributionId: '00000000-0000-0000-0000-000000000000',
      gender: 'N/A',
      lastUpdated: new Date,
      userIdLastUpdated: this.accountService.getUserId(),
      identifier: 0,
      lastStatus: 'Activo',
      amountSavings: 0,
      credit: 0,
      NumberOfLoans: 0,
      type: '',
      fortnight: '',
      clabe: '',
      curp: '',
      bankId: '00000000-0000-0000-0000-000000000000',
      member: false,
      amountSavingsFortnight: 0,
      document: 0,
      documentNumber: '',
      bank: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: false,
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: 'N/A',
        code: 'N/A'
      },
      contribution: {},
      teacherStatus: [],
      beneficiaries: [],
      teacherPhones: [],
      contributionPercentages: [],
      loans: [],
      savings: [],
      financialliquidity: [],
      payments: [],
      supports: [],
      applied: true,
      lowStaffing: [],
      itIsSupplier: false
    },
    movementType: '',
    condoneInterest: false,
    applySupport: true,
    account: '',
    bankId: '',
    beneficiaries: [],
    lowStaffLoans: []
  };

  constructor(
    private teacherService: TeacherService,
    public accountService: AccountService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private helperService: HelperService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }
      this.accountService.getApplications('administration', 'teachers')
        .subscribe(applications => {
          this.applications = applications;
          this.accountService.getApplications('administration', 'supports')
            .subscribe(applicationSupports => {
              this.applications = applications.concat(applicationSupports);
              this.activatedRoute.params.subscribe(params => {
                if (params['searchValue']) {
                  this.searchValue = params['searchValue'];
                  this.loadData(this.searchValue);
                } else {
                  this.loadData('null');
                }
              });
            }, error => this.helperService.setErrorMessage(error));
        }, error => this.helperService.setErrorMessage(error));
    });
  }

  loadData(value: string) {
    this.loadingData = true;
    this.teacherService.get(value)
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
    },
    error => this.helperService.setErrorMessage(error));
  }

  search() {
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search );
  }

  agregar() {
    const text = this.searchValue;
    this.router.navigate( ['administration/teachers/add', '', text] );
  }

  async showConfirmReentry( teacher: any ) {
    const options: SelectOption[] = [
      { value: 'Reingreso', text: 'Reingreso' },
      { value: 'Alta Jubilación', text: 'Alta Jubilación' }
    ];

    const status = await this.notificationService.showConfirmSelect(
      `¿Seguro que desea reingrresar al maestro ${teacher.rfc}-${teacher.name}?`,
      options,
      'Reingreso',
      'Seleccione el estatus a actualizar',
      'Debes de seleccionar un estatus');
    if(status !== null) {
      this.teacherService.reentry(teacher.id, status)
      .subscribe(() => {
        teacher.type = status;
        this.notificationService.toastConfirm('Se actualizo correctamente el estatus');
        this.loadingData = false;
      });
    }
  }

  showConfirmDirectDebitPayment( teacher: any ) {
    this.notificationService.showConfirm(`Seguro que desea domiciliar el pago del maestro ${teacher.name}.`)
    .then(result => {
      if(result.isConfirmed) {
        this.teacherService.directDebitPayment( teacher.id )
        .subscribe( () => {
          teacher.type = 'Básico Domiciliado';
          this.notificationService.toastConfirm('Se domicilio el pago.');
        });
      }
    });
  }

  showConfirmRemoveDirectDebitPayment( teacher: any ) {
    this.notificationService.showConfirm(`Seguro que desea eliminar la domiciliación el pago del maestro ${teacher.name}`)
    .then(result => {
      if(result.isConfirmed) {
        this.teacherService.removeDirectDebitPayment( teacher.id )
        .subscribe( () => {
          teacher.type = 'Básico';
          this.notificationService.toastConfirm('Se removio la domiciliación el pago.');
        });
      }
    });
  }

  getSavings5Percentage( data: any[] ): number {
    const percentage = 0.00208;
    let interestWithImport = 0;
    let amount = 0;
    data.forEach(year => {
      // let interests = 0.00;
      let interestPeritem = 0;
      const arrayYear = Object.keys(year).map(function (key) {
        return [String(key), year[key]];
      });
      for (let index = 0; index < arrayYear.length; index++) {
        if (arrayYear[index][0].slice(0, 3).toUpperCase() === 'QNA') {
          // interests = (interests + arrayYear[index][1]) * percentage;
          if ( arrayYear[index][1] !== 0 ) {
            const interestFortnight = (interestWithImport + arrayYear[index][1]) * percentage;
            interestPeritem += interestFortnight;
            interestWithImport += arrayYear[index][1] + interestFortnight;
          }

          year.interests = parseFloat(interestPeritem.toFixed(2));
          // this.amountInterests += year.interests;
        }
      }
      // year.interests = parseFloat(interests.toFixed(2)) - year.total;
      // this.lowStaff.amountInterests += year.interests;
      // this.lowStaff.amountSavings += year.total;
      amount += year.interests;
      // this.amount += year.total;
    });
    return amount;
  }

  calculateAmount(data: any[], percentage: number) {
    let interestWithImport = 0;
    data.forEach(year => {
      // let interests = 0.00;
      let interestPeritem = 0;
      const arrayYear = Object.keys(year).map(function (key) {
        return [String(key), year[key]];
      });
      for (let index = 0; index < arrayYear.length; index++) {
        if (arrayYear[index][0].slice(0, 3).toUpperCase() === 'QNA') {
          // interests = (interests + arrayYear[index][1]) * percentage;
          if ( arrayYear[index][1] !== 0 ) {
            const interestFortnight = (interestWithImport + arrayYear[index][1]) * percentage;
            interestPeritem += interestFortnight;
            interestWithImport += arrayYear[index][1] + interestFortnight;
          }

          year.interests = parseFloat(interestPeritem.toFixed(2));
          // this.amountInterests += year.interests;
        }
      }
      // year.interests = parseFloat(interests.toFixed(2)) - year.total;
      this.lowStaff.amountInterests += year.interests;
      this.lowStaff.amountSavings += year.total;
      this.lowStaff.amount = year.interests + year.total;
      // this.amount += year.total;
    });
  }

  haveLoanActive( loans: Array<LoanInterface>): boolean {
    return loans.some(l => l.status === 'Activo');
  }
}
