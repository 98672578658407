import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { SupportInterface } from './interfaces/support.interface';
import { SupportService } from './services/support.service';

@Component({
  selector: 'app-support-detail',
  templateUrl: './support-detail.component.html',
  styles: []
})
export class SupportDetailComponent implements OnInit {
  errorMessage = '';
  loadingData = false;
  searchValue = '';
  support: SupportInterface = {
    acceptanceDate: new Date(),
    active: true,
    age: 0,
    beneficiary: {},
    beneficiaryId: '',
    block: 0,
    checkNumber: '',
    comment: '',
    curp: '',
    date: new Date(),
    id : '',
    lastUpdated: new Date(),
    movementDate: new Date(),
    name: '',
    occupation: '',
    relationship: '',
    status: '',
    teacher: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      regionId: '00000000-0000-0000-0000-000000000000',
      rfc: '',
      name: '',
      street: '',
      number: '',
      colony: '',
      zipCode: 0,
      locality: '',
      email: '',
      admission: '',
      lastStatus: 'Activo',
      birthdate: '',
      contributionId: '00000000-0000-0000-0000-000000000000',
      gender: '',
      lastUpdated: new Date,
      userIdLastUpdated: this.accountService.getUserId(),
      identifier: 0,
      amountSavings: 0,
      credit: 0,
      type: '',
      applied: true,
      fortnight: '',
      clabe: '',
      curp: '',
      bankId: '00000000-0000-0000-0000-000000000000',
      member: false,
      amountSavingsFortnight: 0,
      document: 0,
      documentNumber: '',
      NumberOfLoans: 0,
      bank: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: false,
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: '',
        code: ''
      },
      contribution: {},
      teacherStatus: [],
      beneficiaries: [],
      teacherPhones: [],
      contributionPercentages: [],
      loans: [],
      savings: [],
      financialliquidity: [],
      payments: [],
      supports: [],
      lowStaffing: [],
      itIsSupplier: false
    },
    teacherId: '',
    type: '',
    userId: '',
    userIdLastUpdated: '',
    wayToPay: '',
    amount: 0,
    account: '',
    bankId: '',
    email: '',
    phone: ''
  };

  constructor(
    private supportService: SupportService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public accountService: AccountService
  ) {

  }

  ngOnInit() {
    this.loadingData = true;
    this.activatedRoute.params.subscribe(params => {
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }

      if ( params['id'] ) {
        this.supportService.getById(params['id'])
          .subscribe(data => {
            this.support = data;
            this.loadingData = false;
          },
            error => this.setErrorMessage(error));
      } else {
        this.router.navigate(['/administration/supports', this.searchValue]);
      }
    });
  }

  setErrorMessage( error: any ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

}
