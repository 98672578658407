import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BankInterface } from '../banks/interfaces/bank.interface';
import { TeacherInterface } from '../teachers/interface/teacher.interface';
import { TeacherService } from '../teachers/services/teacher.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { ImproperPaymentService } from './services/improper-payment.service';
import { BankService } from '../banks/services/bank.service';
import { ImproperPaymentCreateInterface } from './interfaces/improper-payment-create.interface';

@Component({
  selector: 'app-improper-payment',
  templateUrl: './improper-payment.component.html',
  styles: [
  ]
})
export class ImproperPaymentComponent implements OnInit {
  loadingData = false;
  sendingData = false;
  errorMessage = '';
  forma: UntypedFormGroup;
  banks: BankInterface[] = [];
  teacher: TeacherInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    rfc: '',
    name: '',
    street: '',
    number: '',
    colony: '',
    zipCode: 0,
    locality: '',
    email: '',
    admission: '',
    birthdate: '',
    contributionId: '00000000-0000-0000-0000-000000000000',
    gender: '',
    identifier: 0,
    amountSavings: 0,
    lastStatus: 'Activo',
    applied: true,
    credit: 0,
    lastUpdated: new Date,
    type: '',
    fortnight: '',
    userIdLastUpdated: this.accountService.getUserId(),
    regionId: '00000000-0000-0000-0000-000000000000',
    clabe: '',
    curp: '',
    bankId: '00000000-0000-0000-0000-000000000000',
    member: true,
    amountSavingsFortnight: 0,
    document: 0,
    documentNumber: '',
    NumberOfLoans: 0,
    bank: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: false,
      lastUpdated: new Date(),
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      name: '',
      code: ''
    },
    contribution: {},
    teacherStatus: [],
    beneficiaries: [],
    teacherPhones: [],
    contributionPercentages: [],
    loans: [],
    savings: [],
    financialliquidity: [],
    payments: [],
    supports: [],
    lowStaffing: [],
    itIsSupplier: false
  };
  impropertPayment: ImproperPaymentCreateInterface = {
    teacherId: '',
    amount: 0,
    curp: '',
    rfc: '',
    name: '',
    wayToPay: '',
    movementDate: new Date(`${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`),
    comment: '',
    userId: this.accountService.getUserId(),
    account: '',
    email: '',
    phone: '',
    bankId: ''
  };

  constructor( private teacherService: TeacherService,
    private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private improperPaymentService: ImproperPaymentService,
    private router: Router,
    private bankService: BankService ) { }

  ngOnInit(): void {
    this.loadingData = true;
    this.buildForm();
    this.activatedRoute.params.subscribe(params => {
      this.bankService.get('null').subscribe(
        banks => {
          this.banks = banks;
          if ( params['id'] ) {
            this.teacherService.getById(params['id']).subscribe( teacher => {
              this.teacher = Object.assign({}, teacher);
              // tslint:disable-next-line: max-line-length
              if ( this.teacher.type === 'Jubilación' || this.teacher.type === 'Defunción' || this.teacher.type === 'Renuncia' || this.teacher.type === 'Baja Voluntaria' ) {
                this.evaluateAmount(  );
              }
              if ( this.teacher.bankId ) {
                this.forma.controls['bankId'].patchValue(this.teacher.bankId);
                this.forma.controls['account'].patchValue(this.teacher.clabe);
              }
              this.forma.controls['email'].patchValue( this.teacher.email );
              this.forma.controls['teacherId'].patchValue( this.teacher.id );
              this.forma.controls['userId'].patchValue( this.accountService.getUserId() );
              this.loadingData = false;
            }, error => this.setErrorMessage(error));
          } else {
            this.errorMessage = 'No se encontro el maestro';
            this.loadingData = true;
          }
        }, error => this.setErrorMessage(error));
    }, error => this.setErrorMessage(error));
  }

  evaluateAmount( ): void {
    const lowstaffing = this.teacher.lowStaffing.find(l => l.type === this.teacher.type && l.status === 'Aplicado');
    let year = 0;
    let fortnigh = 0;
    let amountImpropertPayment = 0;
    if ( lowstaffing.fortnigh  ) {
      year = parseInt( lowstaffing.fortnigh.split('-')[0], null );
      fortnigh = parseInt( lowstaffing.fortnigh.split('-')[1], null );
      const savings = this.teacher.savings.filter( s => s.year >= year ).sort(( savignOne, savingTwo ) => savignOne.year - savingTwo.year);

      savings.forEach(saving => {
        const arrayColumns = Object.keys(saving).map(function(key) {
          return [String(key), saving[key]];
        });
        for (let index = 0; index < arrayColumns.length; index++) {
          if ( arrayColumns[index][0].slice(0, 9).toUpperCase() === 'FORTNIGHT' ) {
            const fortnightTemp = parseInt(arrayColumns[index][0].slice( 9, arrayColumns[index][0].length) , null);
            if ( saving.year === year ) {
              if ( fortnightTemp > fortnigh ) {
                amountImpropertPayment += parseFloat( arrayColumns[index][1]);
              }
            } else {
              amountImpropertPayment += parseFloat( arrayColumns[index][1]);
            }
          }
        }
      });
    }
    this.forma.controls['amount'].patchValue( amountImpropertPayment.toFixed(2) );
  }

  setErrorMessage( error ) {
    console.log(error);
    if ( error.error ) {
      this.errorMessage = error.error;
    } else {
      this.errorMessage = error.message;
    }

    this.loadingData = false;
    this.sendingData = false;
  }

  buildForm( ) {
    this.forma = this.formBuilder.group({
      teacherId: ['', [Validators.required]],
      rfc: ['', [Validators.maxLength(13)]],
      curp: ['', [Validators.maxLength(18)]],
      name: ['', [Validators.minLength(6)]],
      wayToPay: ['', [Validators.required]],
      // tslint:disable-next-line:max-line-length
      movementDate: [`${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`, [Validators.required]],
      comment: [''],
      userId: ['', [Validators.required]],
      otherPerson: [''],
      account: ['', [Validators.minLength(10)]],
      amount: [ '0' , [Validators.required, Validators.min(1)]],
      email: ['', [Validators.email]],
      phone: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      // phone: ['', [Validators.pattern('/^\(?(\d{3})\)?[-]?(\d{3})[-]?(\d{4})$/')]],
      bankId: ['']
    });
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  formValid(): boolean {
    if ( !this.forma.valid ) {
      return false;
    }

    if ( this.forma.controls['otherPerson'].value === true ) {
      // tslint:disable-next-line: max-line-length
      if ( this.forma.controls['rfc'].value === '' || this.forma.controls['curp'].value === '' || this.forma.controls['name'].value === ''  ) {
        return false;
      }
    }

    if ( this.forma.controls['wayToPay'].value === 'Transferencia' ) {
      if ( this.forma.controls['email'].value === '' || this.forma.controls['phone'].value === '' || this.forma.controls['bankId'].value === '' || this.forma.controls['account'].value === '' ) {
        return false;
      }
    }
    return true;
  }

  disabledAmount(): boolean {
    // tslint:disable-next-line: max-line-length
    if ( this.teacher.type === 'Jubilación' || this.teacher.type === 'Defunción' || this.teacher.type === 'Renuncia' || this.teacher.type === 'Baja Voluntaria' ) {
      return true;
    }
    return false;
  }

  sendForm(): void {
    this.sendingData = true;
    this.impropertPayment = Object.assign({}, this.forma.value);

    if ( this.forma.controls['otherPerson'].value === false || this.forma.controls['otherPerson'].value === '' ) {
      this.impropertPayment.name = this.teacher.name;
      this.impropertPayment.curp = ( this.teacher.rfc === '' ) ? this.teacher.curp : this.teacher.rfc;
      this.impropertPayment.email = this.teacher.email;
      if ( this.teacher.teacherPhones.length > 0 ) {
        this.impropertPayment.phone = this.teacher.teacherPhones[0].phone;
      }
    }

    this.improperPaymentService.create( this.impropertPayment ).subscribe( success => {
      this.router.navigate(['administration/teachers']);
    }, err => this.setErrorMessage(err));
  }

}
