import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { TeacherInterface } from '../../administration/teachers/interface/teacher.interface';
import { TeacherService } from '../../administration/teachers/services/teacher.service';

@Component({
  selector: 'app-account-status',
  templateUrl: './account-status.component.html',
  styles: []
})
export class AccountStatusComponent implements OnInit {
  loadingData = false;
  returnUrl = '#/reports/account-statements'
  loan: any;
  fullPayments = 0;
  incompletePayments = 0;
  errorMessage = '';

  amountCapitalPayments = 0;
  balanceCapitalPayments = 0;
  amountInterestPayments = 0;
  balanceInterestPayments = 0;
  amountLifeInsurancePayments = 0;
  balanceLifeInsurancePayments = 0;

  fortnightLastPayment = '';
  teacher: TeacherInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    rfc: '',
    name: '',
    street: '',
    number: '',
    colony: '',
    zipCode: 0,
    locality: '',
    email: '',
    admission: '',
    birthdate: '',
    contributionId: '00000000-0000-0000-0000-000000000000',
    gender: '',
    identifier: 0,
    amountSavings: 0,
    lastStatus: 'Activo',
    applied: true,
    credit: 0,
    lastUpdated: new Date,
    type: '',
    fortnight: '',
    userIdLastUpdated: this.accountService.getUserId(),
    regionId: '00000000-0000-0000-0000-000000000000',
    clabe: '',
    curp: '',
    bankId: '00000000-0000-0000-0000-000000000000',
    member: true,
    amountSavingsFortnight: 0,
    document: 0,
    documentNumber: '',
    NumberOfLoans: 0,
    bank: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: false,
      lastUpdated: new Date(),
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      name: '',
      code: ''
    },
    contribution: {},
    teacherStatus: [],
    beneficiaries: [],
    teacherPhones: [],
    contributionPercentages: [],
    loans: [],
    savings: [],
    financialliquidity: [],
    payments: [],
    supports: [],
    lowStaffing: [],
    itIsSupplier: false
  };

  constructor(public activatedRoute: ActivatedRoute,
    private router: Router,
    private teacherService: TeacherService,
    public accountService: AccountService ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params['searchValue']) {
        this.returnUrl = `${ this.returnUrl }/${ params['searchValue'] }`;
      }

      if (params['id']) {
        let yearLocal = 0;
        let fortnightLocal = 0;
        let fortnightLocalLastPayment = 0;
        let amountFortnight = 0;
        let startYear = 0;
        let startFortnight = 0;
        let endYear = 0;
        let endFortnight = 0;
        let discount = 0;
        let fortnightCapital = 0;
        let fortnightInterest = 0;
        let fortnightLifeInsurance = 0;
        this.teacherService.getById(params['id']).subscribe(
          (data) => {
            this.teacher = data;
            if( !this.teacher.active ) {
              const loanSorted = this.teacher.loans.sort(( a, b ) => (a.acceptanceDate > b.acceptanceDate) ? -1 : 1 )
              this.loan = loanSorted[0];
            } else {
              this.loan = this.teacher.loans.find( l => l.status === 'Activo' );
            }
            startYear = parseInt( this.loan.fortnightStart.split('-')[0] );
            startFortnight = parseInt( this.loan.fortnightStart.split('-')[1] );
            endYear = parseInt( this.loan.fortnightEnd.split('-')[0] );
            endFortnight = parseInt( this.loan.fortnightEnd.split('-')[1] );
            discount = parseFloat( this.loan.discount );
            fortnightCapital = parseFloat( this.loan.loanParameter.fortnightCapital );
            fortnightInterest = parseFloat( this.loan.loanParameter.fortnightInterest ) + parseFloat( this.loan.loanParameter.fortnightIVA );
            fortnightLifeInsurance = parseFloat( this.loan.loanParameter.fortnightLifeInsurance );
            const paymentsSorted = this.loan.payments.sort( (a, b) => a.year < b.year ? -1 : 1 );
            paymentsSorted.forEach(( payment ) => {
              yearLocal = parseInt( payment.year );
              const arrayYear = Object.keys(payment).map(function (key) {
                return [String(key), payment[key]];
              });

              for (let index = 0; index < arrayYear.length; index++) {
                if ( arrayYear[index][0].slice(0, 9).toUpperCase() === 'FORTNIGHT' ) {
                  amountFortnight = parseFloat( arrayYear[index][1] );
                  fortnightLocal = arrayYear[index][0].slice(9, arrayYear[index][0].toString().length);
                  if( amountFortnight > 0 ) {
                    fortnightLocalLastPayment = arrayYear[index][0].slice(9, arrayYear[index][0].toString().length);
                  }
                  if( startYear === yearLocal && fortnightLocal >= startFortnight ) {
                    this.evalueateFortnight( discount, amountFortnight, fortnightCapital, fortnightInterest, fortnightLifeInsurance );
                  } else if( yearLocal > startYear && yearLocal < endYear ) {
                    this.evalueateFortnight( discount, amountFortnight, fortnightCapital, fortnightInterest, fortnightLifeInsurance );
                  } else if( yearLocal === endYear && fortnightLocal <= endFortnight ) {
                    console.log( `Year local ${ yearLocal } year end ${ endYear } Qna local ${ fortnightLocal } Qna end ${ endFortnight } ` );
                    this.evalueateFortnight( discount, amountFortnight, fortnightCapital, fortnightInterest, fortnightLifeInsurance );
                  }
                }
              }
            });
            if( fortnightLocalLastPayment.toString().length === 1 ) {
              this.fortnightLastPayment = `${ yearLocal }-0${ fortnightLocalLastPayment }`
            } else {
              this.fortnightLastPayment = `${ yearLocal }-${ fortnightLocalLastPayment }`
            }

            this.evaluateFinishedLoan( this.loan, endYear, endFortnight, yearLocal, fortnightLocal );

            this.loadingData = false;
          },
          (error) => this.setErrorMessage(error)
        );
      } else {
        this.router.navigate([this.returnUrl]);
      }
    });
  }

  evaluateFinishedLoan( loan: any, endYear: number, endFortnight: number, yearLastPayment: number, fortnightLastPayment: number ) {
    const completeEndFortnight = (  endFortnight.toString().length === 1 ) ? parseFloat( `${ endYear }0${ endFortnight }` ) : parseFloat( `${ endYear }${ endFortnight }` );
    const completeLastPaymentFortnight = (  fortnightLastPayment.toString().length === 1 ) ? parseFloat( `${ yearLastPayment }0${ fortnightLastPayment }` ) : parseFloat( `${ yearLastPayment }${ fortnightLastPayment }` );
    if( completeEndFortnight > completeLastPaymentFortnight ) {
      const fortnightCapital = parseFloat( this.loan.loanParameter.fortnightCapital );
      const fortnightInterest = parseFloat( this.loan.loanParameter.fortnightInterest ) + parseFloat( this.loan.loanParameter.fortnightIVA );
      const fortnightLifeInsurance = parseFloat( this.loan.loanParameter.fortnightLifeInsurance );
      const yearsPending =  endYear - yearLastPayment;
      let fortnightPendings = (yearsPending * 24) - (  24 - endFortnight );
      this.balanceCapitalPayments += (fortnightPendings * fortnightCapital);
      this.balanceInterestPayments += (fortnightPendings * fortnightInterest);
      this.balanceLifeInsurancePayments += (fortnightPendings * fortnightLifeInsurance);
    }
  }

  evalueateFortnight( 
    discount: number,
    amountFortnight: number,
    fortnightCapital: number,
    fortnightInterest: number,
    fortnightLifeInsurance: number ) {

    if( amountFortnight === 0 ) {
      console.log( 'Entro al if 0' )
      this.balanceCapitalPayments += fortnightCapital;
      this.balanceInterestPayments += fortnightInterest;
      this.balanceLifeInsurancePayments += fortnightLifeInsurance;
    } else if( amountFortnight === discount ) {
      this.amountCapitalPayments += fortnightCapital;
      this.amountInterestPayments += fortnightInterest;
      this.amountLifeInsurancePayments += fortnightLifeInsurance;
      this.fullPayments++
    } else if( amountFortnight < parseFloat( this.loan.discount ) ) {
      console.log( `Entro al amount ${ amountFortnight } < discount ${ this.loan.discount } ` )
      this.amountCapitalPayments += ( amountFortnight > fortnightCapital ) ? fortnightCapital : fortnightCapital - amountFortnight;
      this.balanceCapitalPayments += ( amountFortnight > fortnightCapital ) ? 0 : fortnightCapital - amountFortnight;
      amountFortnight -= fortnightCapital;
      this.amountInterestPayments += fortnightInterest;
      this.amountLifeInsurancePayments += fortnightLifeInsurance;
      if( amountFortnight > 0 ) {
        this.amountInterestPayments -= ( amountFortnight > fortnightInterest ) ? 0 : fortnightInterest - amountFortnight;
        this.balanceInterestPayments += ( amountFortnight > fortnightInterest ) ? 0 : fortnightInterest - amountFortnight;
        amountFortnight -= fortnightInterest;
      } else {
        this.amountInterestPayments -= fortnightInterest;
        this.balanceInterestPayments += fortnightInterest;
      }

      if( amountFortnight > 0 ) {
        this.amountLifeInsurancePayments -= ( amountFortnight > fortnightLifeInsurance ) ? 0 : fortnightLifeInsurance - amountFortnight;
        this.balanceLifeInsurancePayments += ( amountFortnight > fortnightLifeInsurance ) ? 0 : fortnightLifeInsurance - amountFortnight;
      } else {
        this.amountLifeInsurancePayments -= fortnightLifeInsurance;
        this.balanceLifeInsurancePayments += fortnightLifeInsurance;
      }
      this.incompletePayments++;
    }
  }

  setErrorMessage(error) {
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.loadingData = false;
    }
  }

  getPayments( payments: any[] ): any[] {
    return payments.sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 );
  }

}
