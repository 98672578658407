import { Component, OnInit  } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TeacherInterface } from './interface/teacher.interface';
import { TeacherService } from './services/teacher.service';
import { AccountService } from '../../account/login/services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PhoneTypeService } from '../../catalogs/phone-types/services/phone-type.service';
import { PhoneTypeInterface } from '../../catalogs/phone-types/interfaces/phone-type.interface';
import { RegionInterface } from '../../catalogs/regions/interfaces/region.interface';
import { RegionService } from '../../catalogs/regions/services/region.service';
import { RelationshipInterface } from '../../catalogs/relationships/interfaces/relationship.interface';
import { RelationshipService } from '../../catalogs/relationships/services/relationship.service';
import { ContributionService } from '../../catalogs/contributions/services/contribution.service';
import { ContributionInterface } from '../../catalogs/contributions/interfaces/contribution.interface';
import { BankInterface } from '../banks/interfaces/bank.interface';
import { BankService } from '../banks/services/bank.service';
import { ContributionInfointerface } from './interface/contributionInfointerface';
import { validateCURPLength } from '../../../services/app.validator';
import { NotificationService } from '../.../../../../../app/services/notification.service';

@Component({
  selector: "app-teacher",
  templateUrl: "./teacher.component.html",
  styles: [],
})
export class TeacherComponent implements OnInit {
  title = "Agregar";
  returnPath = "../../../";
  forma: UntypedFormGroup;
  loadingData = false;
  sendData = false;
  showBankInformation = false;
  searchValue = "";
  phoneTypes: PhoneTypeInterface[] = [];
  relationships: RelationshipInterface[] = [];
  regions: RegionInterface[] = [];
  contributions: ContributionInterface[] = [];
  fortnights: string[] = [];
  banks: BankInterface[] = [];
  teacherType = "";
  teacherAccount = '';
  teacherBankId = '';
  teacher: TeacherInterface = {
    id: "00000000-0000-0000-0000-000000000000",
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    rfc: "",
    name: "",
    street: "",
    number: "",
    colony: "",
    zipCode: 0,
    locality: "",
    email: "",
    admission: "",
    birthdate: "",
    contributionId: "00000000-0000-0000-0000-000000000000",
    gender: "",
    identifier: 0,
    amountSavings: 0,
    lastStatus: "Activo",
    applied: true,
    credit: 0,
    lastUpdated: new Date(),
    type: "",
    fortnight: "",
    userIdLastUpdated: this.accountService.getUserId(),
    regionId: "00000000-0000-0000-0000-000000000000",
    clabe: "",
    curp: "",
    bankId: "00000000-0000-0000-0000-000000000000",
    member: true,
    amountSavingsFortnight: 0,
    document: 0,
    documentNumber: "",
    NumberOfLoans: 0,
    bank: {
      id: "00000000-0000-0000-0000-000000000000",
      userId: "00000000-0000-0000-0000-000000000000",
      date: new Date(),
      active: false,
      lastUpdated: new Date(),
      userIdLastUpdated: "00000000-0000-0000-0000-000000000000",
      name: "",
      code: "",
    },
    contribution: {},
    teacherStatus: [],
    beneficiaries: [],
    teacherPhones: [],
    contributionPercentages: [],
    loans: [],
    savings: [],
    financialliquidity: [],
    payments: [],
    supports: [],
    lowStaffing: [],
    itIsSupplier: false,
  };

  constructor(
    private teacherService: TeacherService,
    public accountService: AccountService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private phoneTypeService: PhoneTypeService,
    private formBuilder: UntypedFormBuilder,
    private regionService: RegionService,
    private relationshipService: RelationshipService,
    private contributionService: ContributionService,
    private bankService: BankService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.loadingData = true;
      this.buildForm();

      if (params["searchValue"]) {
        this.searchValue = params["searchValue"];
      }

      this.phoneTypeService.get("null").subscribe(
        (phones) => {
          this.phoneTypes = phones;
          this.regionService.get("null").subscribe(
            (regions) => {
              this.regions = regions;
              this.relationshipService.get("null").subscribe(
                (relationships) => {
                  this.relationships = relationships;
                  this.contributionService.get("null").subscribe(
                    (contributions) => {
                      this.contributions = contributions;
                      this.fortnights = this.getFortnights(8);
                      this.bankService.get("null").subscribe(
                        (banks) => {
                          this.banks = banks;
                          if (params["id"]) {
                            this.title = "Editar";
                            this.teacher.id = params["id"];
                            this.teacherService.getById(params["id"]).subscribe(
                              (teacher) => {
                                this.returnPath = "../../";
                                this.teacher = Object.assign({}, teacher);
                                if (this.teacher.admission) {
                                  this.teacher.admission =
                                    this.teacher.admission.substr(0, 10);
                                }
                                if (
                                  this.teacher.type !== "Básico" &&
                                  this.teacher.type !== "Reingreso"
                                ) {
                                  this.showBankInformation = true;
                                }
                                this.teacher.birthdate =
                                  this.teacher.birthdate.substr(0, 10);
                                this.forma.patchValue(this.teacher);
                                this.teacherAccount = this.teacher.clabe;
                                this.teacherBankId = this.teacher.bankId; 
                                this.setTeacherPhones();
                                this.setBeneficiaries();
                                this.loadingData = false;
                              },
                              (error) => this.errorMessage(error)
                            );
                          } else {
                            this.teacherService.getIdentifier().subscribe(
                              (identifier) => {
                                this.teacher.identifier = identifier;
                                this.forma.patchValue(this.teacher);
                                this.loadingData = false;
                              },
                              (error) => this.errorMessage(error)
                            );
                          }
                        },
                        (error) => this.errorMessage(error)
                      );
                    },
                    (error) => this.errorMessage(error)
                  );
                },
                (error) => this.errorMessage(error)
              );
            },
            (error) => this.errorMessage(error)
          );
        },
        (error) => this.errorMessage(error)
      );
    });
  }

  setFormatDate(fecha: string): string {
    return fecha.substr(0, 10);
  }

  handlerType(type: string) {
    if (type !== "Básico") {
      this.showBankInformation = true;
      this.forma.controls["bankId"].patchValue("");
      this.forma.controls["clabe"].patchValue("");
      this.forma.controls["document"].patchValue("");
      this.forma.controls["documentNumber"].patchValue("");
    } else {
      this.showBankInformation = false;
      // this.forma.controls['bankId'].patchValue('00000000-0000-0000-0000-000000000000');
      this.forma.controls["bankId"].patchValue("");
      this.forma.controls["clabe"].patchValue("N/A");
      this.forma.controls["document"].patchValue("0");
      this.forma.controls["documentNumber"].patchValue("N/A");
    }
  }

  setTeacherPhones() {
    const phonesArr = this.forma.get("teacherPhones") as UntypedFormArray;
    this.teacher.teacherPhones.forEach((phone) => {
      const phoneToAdd = this.formBuilder.group({
        id: phone.id,
        userId: phone.userId,
        date: phone.date,
        active: phone.active,
        lastUpdated: phone.lastUpdated,
        userIdLastUpdated: phone.userIdLastUpdated,
        teacherId: phone.teacherId,
        phoneTypeId: [phone.phoneTypeId, Validators.required],
        phone: [phone.phone, Validators.minLength(10)],
      });
      phonesArr.push(phoneToAdd);
    });
  }

  setBeneficiaries() {
    const phonesArr = this.forma.get("beneficiaries") as UntypedFormArray;
    this.teacher.beneficiaries.forEach((beneficiary) => {
      const phoneToAdd = this.formBuilder.group({
        id: beneficiary.id,
        userId: beneficiary.userId,
        date: beneficiary.date,
        active: beneficiary.active,
        lastUpdated: beneficiary.lastUpdated,
        userIdLastUpdated: beneficiary.userIdLastUpdated,
        teacherId: beneficiary.teacherId,
        relationshipId: beneficiary.relationshipId,
        name: beneficiary.name,
        percentage: beneficiary.percentage,
      });
      phonesArr.push(phoneToAdd);
    });
  }

  buildForm() {
    this.forma = this.formBuilder.group(
      {
        id: [""],
        userId: [""],
        date: [""],
        active: [""],
        name: ["", [Validators.required, Validators.minLength(3)]],
        regionId: ["", [Validators.required]],
        rfc: ["", [Validators.minLength(13)]],
        street: ["", [Validators.required, Validators.minLength(3)]],
        number: ["", [Validators.required]],
        colony: ["", [Validators.required, Validators.minLength(3)]],
        zipCode: [
          "",
          [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(5),
          ],
        ],
        locality: ["", [Validators.minLength(3)]],
        email: ["", [Validators.required, Validators.email]],
        admission: [""],
        birthdate: ["", [Validators.required]],
        contributionId: ["", [Validators.required]],
        gender: ["", [Validators.required]],
        identifier: ["", [Validators.required]],
        amountSavings: [""],
        lastStatus: [""],
        credit: [""],
        type: ["", [Validators.required]],
        fortnight: ["", [Validators.required]],
        curp: [""],
        clabe: [""],
        bankId: [""],
        member: ["true"],
        amountSavingsFortnight: ["0"],
        document: [""],
        documentNumber: [""],
        teacherStatus: [],
        teacherPhones: this.formBuilder.array([]),
        loans: [],
        savings: [],
        beneficiaries: this.formBuilder.array([]),
      },
      {
        validators: [validateCURPLength],
      }
    );
  }

  get teacherPhones() {
    const arr = this.forma.get("teacherPhones") as UntypedFormArray;
    return arr;
  }

  sendForm() {
    this.sendData = true;

    const itIsSupplier =
      this.forma.controls["type"].value !== "Básico" &&
      this.teacher.bankId === this.forma.controls["bankId"].value &&
      this.teacher.clabe === this.forma.controls["clabe"].value
        ? this.teacher.itIsSupplier
        : false;
    const applied =
      this.forma.controls["type"].value !== "Básico" &&
      this.teacher.bankId === this.forma.controls["bankId"].value &&
      this.teacher.clabe === this.forma.controls["clabe"].value
        ? this.teacher.applied
        : false;
    this.teacher = Object.assign({}, this.forma.value);
    this.teacher.date = new Date();
    this.teacher.itIsSupplier = itIsSupplier;
    this.teacher.applied = applied;
    if (this.title === "Agregar") {
      this.teacherService.create(this.teacher).subscribe(
        (success) => {
          this.notificationService.toastConfirm(
            "El maestro se agrego correctamente."
          );
          this.router.navigate(["administration/teachers", this.searchValue]);
        },
        (error) => this.errorMessage(error)
      );
    } else {
      this.teacher.userIdLastUpdated = this.accountService.getUserId();
      this.teacher.itIsSupplier = (this.teacher.bankId !== this.teacherBankId || this.teacher.clabe !== this.teacherAccount) ? false : this.teacher.itIsSupplier;
      this.teacherService.update(this.teacher.id, this.teacher).subscribe(
        () => {
          this.notificationService.toastConfirm(
            "El maestro se actualizo correctamente."
          );
          this.router.navigate(["administration/teachers", this.searchValue]);
        },
        (error) => this.errorMessage(error)
      );
    }
  }

  validData(): boolean {
    if (!this.forma.valid) {
      return false;
    }
    // tslint:disable-next-line: max-line-length
    if (
      (this.forma.controls.type.value === "Básico" ||
        this.forma.controls.type.value === "Reingreso") &&
      this.forma.controls.rfc.value?.length < 13
    ) {
      return false;
    }
    // tslint:disable-next-line: max-line-length
    if (
      this.forma.controls.type.value !== "Básico" &&
      this.forma.controls.type.value !== "Reingreso" &&
      (this.forma.controls.curp.value?.length < 18 ||
        this.forma.controls.bankId.value === "" ||
        this.forma.controls.clabe.value === "" ||
        this.forma.controls.document.value === "" ||
        this.forma.controls.documentNumber.value === "" ||
        this.forma.controls.amountSavingsFortnight.value === "")
    ) {
      return false;
    }
    return true;
  }

  addPhone() {
    const phonesArr = this.forma.get("teacherPhones") as UntypedFormArray;
    const phoneFG = this.BuildPhone();
    phonesArr.push(phoneFG);
  }

  addBeneficiary() {
    const beneficiariesArr = this.forma.get(
      "beneficiaries"
    ) as UntypedFormArray;
    const beneficiariesFG = this.BuildBeneficiary();
    beneficiariesArr.push(beneficiariesFG);
  }

  BuildBeneficiary() {
    return this.formBuilder.group({
      id: "00000000-0000-0000-0000-000000000000",
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      lastUpdated: new Date(),
      userIdLastUpdated: this.accountService.getUserId(),
      teacherId: "00000000-0000-0000-0000-000000000000",
      relationshipId: ["", Validators.required],
      name: ["", Validators.minLength(10)],
      percentage: ["", Validators.required],
    });
  }

  BuildPhone() {
    return this.formBuilder.group({
      id: "00000000-0000-0000-0000-000000000000",
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      teacherId: "00000000-0000-0000-0000-000000000000",
      lastUpdated: new Date(),
      userIdLastUpdated: this.accountService.getUserId(),
      phoneTypeId: ["", Validators.required],
      phone: ["", Validators.minLength(10)],
    });
  }

  deletePhone(index: number) {
    const phonesArr = this.forma.get("teacherPhones") as UntypedFormArray;
    const phoneDelete = phonesArr.at(index) as UntypedFormGroup;
    if (
      phoneDelete.controls["id"].value !==
      "00000000-0000-0000-0000-000000000000"
    ) {
      phoneDelete.controls["lastUpdated"].patchValue(new Date());
      phoneDelete.controls["userIdLastUpdated"].patchValue(
        this.accountService.getUserId()
      );
      phoneDelete.controls["active"].patchValue(false);
    } else {
      phonesArr.removeAt(index);
    }
  }

  deleteBeneficiary(index: number) {
    const beneficiariesArr = this.forma.get(
      "beneficiaries"
    ) as UntypedFormArray;
    const beneficiaryDelete = beneficiariesArr.at(index) as UntypedFormGroup;
    if (
      beneficiaryDelete.controls["id"].value !==
      "00000000-0000-0000-0000-000000000000"
    ) {
      beneficiaryDelete.controls["lastUpdated"].patchValue(new Date());
      beneficiaryDelete.controls["userIdLastUpdated"].patchValue(
        this.accountService.getUserId()
      );
      beneficiaryDelete.controls["active"].patchValue(false);
    } else {
      beneficiariesArr.removeAt(index);
    }
  }

  errorMessage(error) {
    console.log(error);
    if (error.error) {
      this.notificationService.showErrorMessage(error.error);
    } else {
      this.notificationService.showErrorMessage(error.message);
    }
    this.sendData = false;
  }

  getFortnights(numberFortnights: number): string[] {
    const arrFortnight = [];
    let counter = 1;
    let numberFortnight = 1;
    const date = new Date(new Date().getFullYear(), 0, 15);
    do {
      arrFortnight.push(
        date.getFullYear().toString() + "-" + this.setFormatDay(numberFortnight)
      );
      if (date >= new Date()) {
        counter++;
      }
      numberFortnight++;
      if (numberFortnight === 25) {
        numberFortnight = 1;
      }
      this.getDateFortnight(date);
    } while (counter <= numberFortnights);
    return arrFortnight;
  }

  getDateFortnight(date: Date): void {
    if (date.getDate() === 15) {
      date.setMonth(date.getMonth() + 1);
      date.setDate(1);
      date.setDate(date.getDate() - 1);
    } else {
      date.setDate(date.getDate() + 15);
    }
  }

  setFormatDay(day: number): string {
    if (day.toString().length === 1) {
      return "0" + day.toString();
    }
    return day.toString();
  }

  async showChangeStatus() {
    let optionsPercentage = this.contributionOptions;
    let optionsFortnights = this.fortnightsOptions;
    let optionsChange = `<option value="">Seleccionar...</option><option value="true">SI</option><option value="false">NO</option>"`;
    const response =
      await this.notificationService.showConfirmChangeContribution(
        "¿Seguro que desea cambiar la aportacón?",
        optionsPercentage,
        optionsFortnights,
        optionsChange
      );
    if (response !== null) {
      const contributionInfo: ContributionInfointerface = {
        teacherId: this.teacher.id,
        contributionId: response[0],
        fortnight: response[1],
        userId: "",
        change: response[2],
      };
      const contribution = this.contributions.find(
        (f) => f.id === contributionInfo.contributionId
      );
      this.forma.controls["contributionId"].patchValue(
        contributionInfo.contributionId
      );
      this.forma.controls["fortnight"].patchValue(contributionInfo.fortnight);
      this.teacher.fortnight = contributionInfo.fortnight;
      this.teacher.contribution = contribution;
      contributionInfo.userId = this.accountService.getUserId();
      this.teacherService.updateContribution(contributionInfo).subscribe(
        () => {},
        (error) => this.errorMessage(error)
      );
    }
  }

  get fortnightsOptions(): string {
    let fortnightsOptions = '<option value="">Seleccionar...</option>';
    this.fortnights.forEach((fortnight) => {
      fortnightsOptions += `<option value="${fortnight}">${fortnight}</option>`;
    });
    return fortnightsOptions;
  }

  get contributionOptions(): string {
    let contributionOptions = '<option value="">Seleccionar...</option>';
    this.contributions.forEach((contribution) => {
      contributionOptions += `<option value="${contribution.id}">${contribution.percentage} %</option>`;
    });
    return contributionOptions;
  }

  validateLengthCURP2(formGroup: UntypedFormGroup): ValidationErrors | null {
    if (formGroup.controls["type"].value === "") {
      return {
        invalidCURP: true,
        message: "Debe de seleccionar el tipo de maestro.",
      };
    }

    switch (formGroup.controls["type"].value) {
      case "Básico":
        if (
          formGroup.controls["curp"].value.length > 0 &&
          (formGroup.controls["curp"].value.length < 18 ||
            formGroup.controls["curp"].value.length > 18)
        ) {
          return {
            invalidCURP: true,
            message: "La longitud del campo debe ser de 18 caracteres.",
          };
        }
        return null;
      default:
        if (
          formGroup.controls["curp"].value.length < 18 ||
          formGroup.controls["curp"].value.length > 19
        ) {
          return {
            invalidCURP: true,
            message: "La longitud del campo debe ser de 18 o 19 caracteres.",
          };
        }
        return null;
    }
  }
}
