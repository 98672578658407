import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoanService } from './services/loan.service';
import { AccountService } from '../../account/login/services/account.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { LoanInterface } from './interfaces/loan.interface';
import { ExcelService } from '../../../services/excel.service';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';

declare let jsPDF;
declare var require: any;

@Component({
  selector: 'app-loans-pending',
  templateUrl: './loans-pending.component.html',
  styles: []
})
export class LoansPendingComponent implements OnInit {
  returnPath = '';
  numberCharacters = 191;
  cuentaBancaria = '1087271129';
  numerocliente = '01714105';
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  sendingData = false;
  searchValue = '';
  drawSelected = '';
  errorMessage = '';
  applications: ApplicationInterface[];
  jsonArray: any[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    private loanService: LoanService,
    private router: Router,
    private excelService: ExcelService,
    private notificationService: NotificationService,
    private helperService: HelperService) { }

  ngOnInit() {
    this.loadingData = true;
    this.accountService.getApplications('administration', 'loans')
      .subscribe(applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if ( params.searchValue ) {
            this.searchValue = params.searchValue;
          }
          this.loadData();
        });
      },
      error => this.helperService.setErrorMessage(error));
  }

  loadData() {
    this.loanService.getPending( )
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
    },
    error => this.helperService.setErrorMessage(error));
  }

  apply() {
    this.sendingData = true;
    // this.generateDocument(this.jsonArray);

    this.loanService.apply( this.accountService.getUserId(), this.jsonArray.filter(l => l.active === true) )
      .subscribe(response => {
        this.generateDocument(response);
        // tslint:disable-next-line:max-line-length
        this.descargarArchivo(this.generarTexto( this.jsonArray.filter(l => l.active === true) ), `${ this.numerocliente }${ new Date().getFullYear().toString().substring(2, 4) }${ this.completeLeft((new Date().getMonth() + 1).toString(), 2, '0') }${ this.completeLeft(new Date().getDate().toString(), 2, '0') }001.chp`);
        this.router.navigate( ['administration/loans', this.searchValue] );
    }, err => this.helperService.setErrorMessage(err));
  }

  descargarArchivo(contenidoEnBlob, nombreArchivo) {
    const reader: FileReader = new FileReader();
    reader.onload = function (event: Event) {
      const save = document.createElement('a');
      save.href = reader.result as string; // event.target.result;
      save.target = '_blank';
      save.download = nombreArchivo || 'archivo.txt';
      const clicEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': true
      });
      save.dispatchEvent(clicEvent);
      (window.URL).revokeObjectURL(save.href);
    };
    reader.readAsDataURL(contenidoEnBlob);
  }

  generarTexto(data: any[]) {
    const texto = [];
    let numberRecord = 1;
    texto.push(this.completeRight(this.getHeader(), this.numberCharacters, '0'));
    data.forEach(element => {
      // tslint:disable-next-line:max-line-length
      texto.push(this.completeRight(`\nD11${ this.completeLeft(numberRecord.toString(), 5, '0') }60${ this.cuentaBancaria }${ this.completeLeft(element.checkNumber, 7, '0') }${ new Date().getFullYear().toString() }${ this.completeLeft((new Date().getMonth() + 1).toString(), 2, '0') }${ this.completeLeft(new Date().getDate().toString(), 2, '0') }20991231${ this.leftPad( (element.amount - (element.openingBonus + element.warranty )).toString() , 13, '0') }0000N${ this.completeRight(element.teacher.name.toUpperCase(), 50, ' ') }MXN`, this.numberCharacters + 1, '0'));
      numberRecord++;
    });
    return new Blob(texto, {
      type: 'text/plain'
    });
  }

  getHeader(): string {
    const grandTotal = this.formatNumber(
      this.jsonArray.reduce((sum, current) => sum + (current.amount - (current.openingBonus + current.warranty)), 0)
      );
    // tslint:disable-next-line:max-line-length
    return `H00001${ new Date().getFullYear().toString() }${ this.completeLeft((new Date().getMonth() + 1).toString(), 2, '0') }${ this.completeLeft(new Date().getDate().toString(), 2, '0') }001${ this.completeLeft(this.jsonArray.length.toString(), 5, '0') }${ this.completeLeft(grandTotal, 18, '0') }00000000000000000000000${ this.completeLeft(this.jsonArray.length.toString(), 5, '0') }${ this.completeLeft(grandTotal, 18, '0') }`;
  }

  formatNumber( amount: number ): string {
    if (amount.toString().indexOf('.') < 0) {
      return `${ amount.toString() }00`;
    }
    return amount.toString().replace('.', '');
  }

  completeLeft(text: string, numberCharacters: number, character: string): string {
    let textReturned = '';
    for (let index = text.length; index < numberCharacters; index++) {
      textReturned = `${ character }${ textReturned }`;
    }
    return textReturned + text;
  }

  leftPad(str: string, len: number, ch= '.'): string {
    len = len - str.length + 1;
    return len > 0 ?
      new Array(len).join(ch) + str : str;
  }

  completeRight(text: string, numberCharacters: number, character: string): string {
    for (let index = text.length; index < numberCharacters; index++) {
      text = `${ text }${ character }`;
    }
    return text;
  }

  generateDocument(data: any[]): void {
    const doc = new jsPDF('portrait');
    // doc.addPage('letter', 'portrait');
    doc.setFont('arial');
    doc.setFontSize(9);
    let contador = 0;
    data.forEach(element => {
      if ( contador > 0 ) {
        doc.addPage();
      }
      const amount = element.amount - (element.openingBonus + element.warranty );
      doc.text(`${ this.formatDate(new Date().getDate()) }/${ this.formatDate(new Date().getMonth()  + 1) }/${ new Date().getFullYear() }`,
      doc.internal.pageSize.width - 70, 19);
      // doc.setFont('arial');
      doc.setFontSize(9);
      doc.setFontStyle('bold');
      doc.text(element.teacher.name.toUpperCase(), 30, 33);
      doc.text(`${ this.numberFormat(amount.toString(), 2) }`, 175 , 33);
      doc.text(this.numberByLetter(amount), 30, 42);
      doc.text(`${ this.leftPad(element.checkNumber, 7, '0') }`, 25, 173);
      doc.setFontSize(14);
      doc.text('NO NEGOCIABLE', 10, 68);
      // doc.setFont('arial');
      doc.setFontSize(9);
      doc.setFontStyle('normal');
      // DETALLE DE LA LIQUIDACIÓN
      doc.text('CORTO PLAZO', 50, 114);
      doc.text(`${ this.numberFormat(element.amount.toString(), 2) }`, 100, 120);
      doc.text(`${ this.numberFormat(element.openingBonus.toString(), 2) }`, 70, 127);
      doc.text('PRIMA DE GARANTIA $', 20, 132);
      doc.text(`${ this.numberFormat(element.warranty.toString(), 2) }`, 70, 132);
      doc.text(`${ this.numberFormat((element.warranty + element.openingBonus).toString(), 2) }`, 70, 150);
      doc.text(`${ this.numberFormat(amount.toString(), 2) }`, 100, 160);
      // doc.setFont('arial');
      doc.setFontSize(7);
      doc.setFontStyle('normal');
      // DATOS DEL PRESTAMO
    //  doc.text(element.loanParameter.prefix, doc.internal.pageSize.width - 40, 111);
      doc.text(element.loanNumber, doc.internal.pageSize.width - 45, 115);
      doc.text(`${ this.formatDate(new Date().getDate()) }/${ this.formatDate(new Date().getMonth() + 1) }/${ new Date().getFullYear() }`,
        doc.internal.pageSize.width - 45, 119);
      doc.text(`$ ${ this.numberFormat(element.discount.toString(), 2) }`, doc.internal.pageSize.width - 45, 124);
      doc.text(element.fortnightStart.toString().replace('-', ''), doc.internal.pageSize.width - 45, 128);
      if ( element.teacher.lastStatus === 'Alta Jubilación' ) {
        doc.text(`${element.remainingFortnightly / 2 } MESES`, doc.internal.pageSize.width - 20, 128);
      } else {
        doc.text(element.remainingFortnightly.toString(), doc.internal.pageSize.width - 10, 128);
      }
      doc.text(element.fortnightEnd.toString().replace('-', ''), doc.internal.pageSize.width - 45, 132);
      // doc.text(`$ ${ this.numberFormat(this.getInterest(element.interests, element.amount, element.remainingFortnightly) , 2) }
      doc.text(`$ ${ this.numberFormat((element.interests * 1.16) , 2) } INCLUYE IVA`, doc.internal.pageSize.width - 45, 136);
      doc.text(`PRIMA DE PROTECCÓN $ ${ this.numberFormat(element.protectionPremium , 2) }`, doc.internal.pageSize.width - 72, 139);
      doc.setFontSize(6);
      doc.setFontStyle('bold');
      doc.text(`$ ${ this.numberFormat(element.amount.toString(), 2) } (${ this.numberByLetter(element.amount) })`,
        doc.internal.pageSize.width - 70, 154);
      doc.text(`MAS LOS INTERESES A LA TASA PACTADA, EL IVA Y`, doc.internal.pageSize.width - 73, 157);
      doc.text(`LA PRIMA DE PROTECCION DEL AHORRO PACTADA.`, doc.internal.pageSize.width - 73, 160);
      // DATOS PERSONALES
      doc.setFontSize(7);
      doc.setFontStyle('normal');
      doc.text(element.teacher.name.toUpperCase(), 25, 187);
      doc.text(element.teacher.rfc.toUpperCase(), 25, 191);
      doc.text(element.individualAccount.toUpperCase(), 86, 191);
      doc.text(element.teacher.street.toUpperCase(), 25, 195);
      if ( element.teacher.teacherPhones.length > 0 ) {
        doc.text(`Tel. ${ element.teacher.teacherPhones[0].phone }`, 86, 198);
      }
      doc.text(element.teacher.colony.toUpperCase(), 25, 199);
      doc.text(element.teacher.zipCode.toString(), 25, 203);
      if ( element.teacher.locality !== null ) {
        doc.text(element.teacher.locality.toString(), 25, 207);
      }
      if ( element.region !== null ) {
        doc.text(element.region.name.toString().toUpperCase(), 25, 211);
      }
      contador++;
    });

    doc.save(`Prestamos.pdf`);
  }

  numberByLetter(amount: number): string {
    const num2Let = require('numalet')();
    const number2letter = num2Let(amount).toString().substring(0, );
    const letter = number2letter.toString().substring(0, number2letter.indexOf(' MXN'));
    const arrQuantity = amount.toString().split('.');
    if ( arrQuantity.length > 1 ) {
      return `${ letter } PESOS ${ arrQuantity[1] }/100 M.N.`;
    }
    return `${ letter } PESOS 00/100 M.N.`;
  }

  getInterest(percentage: number, amount: number, numberFortnight: number): number {
    const interestPerYear = amount * (percentage / 100);
    const interestPerFortnight = interestPerYear / 24;
    return (interestPerFortnight * numberFortnight) * 1.16;
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  deleteItem(loan: LoanInterface) {
    loan.status = 'Cancelado';
    this.loanService.update(loan.id, loan).subscribe(
      () => {
        this.loadData();
        this.notificationService.toastConfirm(`Se elimino el prestamos ${loan.loanNumber}`);
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  showConfirm(loan: LoanInterface) {
    this.notificationService.showConfirm(`¿Seguro que desea eliminar el prestamo ${ loan.loanNumber } ( ${ loan.teacher.name } )?`, 'Cancelación de prestamos')
    .then((result: any) => {
      if(result.isConfirmed) {
        this.deleteItem(loan);
      }
    });
  }

  numberFormat( amount: any, decimals: number ): string {
    amount += '';
    amount = parseFloat(amount.replace(/[^0-9\.]/g, ''));
    decimals =  decimals || 0;
    if ( isNaN(amount) || amount === 0 ) {
      return parseFloat('0').toFixed(decimals);
    }

    amount = '' + amount.toFixed(decimals);

    const amountParts = amount.split('.'), regexp = /(\d+)(\d{3})/;
    while (regexp.test(amountParts[0])) {
    amountParts[0] = amountParts[0].replace(regexp, '$1' + ',' + '$2');
    }

    return amountParts.join('.');
  }

  getExcel(): void {
    // tslint:disable-next-line:max-line-length
    const datos = this.jsonArray.map(({ prefix, loanNumber, teacher, amount, loanParameter, fortnightStart, fortnightEnd, region }) => [ teacher.rfc, teacher.name, prefix, loanNumber, amount, loanParameter.fortnight.numberFortnight, fortnightStart, fortnightEnd, region.name, ( teacher.teacherPhones.length > 0 ) ? teacher.teacherPhones[0].phone : '' ] );
    this.excelService.exportAsExcelFileWithoutHeader(datos,
      `Cheques_Pendientes`);
  }

  handlerChecbox(checked: boolean, item: any): void {
    item.active = checked;
  }

  validData(): boolean {
    return this.jsonArray.some(j => j.active === true);
  }

  handlerSelectAll( value: boolean): void {
    this.jsonArray.forEach(element => {
      element.active = value
    });
  }

}
