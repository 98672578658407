<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Movimientos</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Ahorros</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">

        <form [formGroup]="forma">
            <div class="row form-group">

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Tipo</label>
                        <select class="custom-select" formControlName="detailed">
                            <option value="false" >Agrupado</option>
                            <option value="true" >Detallado</option>
                        </select>
                    </div>
                </div>
        
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Año</label>
                        <select class="custom-select" formControlName="year">
                            <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Mes</label>
                        <select class="custom-select" formControlName="month" (change)="onMonthChange()">
                            <option [value]="month.key" *ngFor="let month of months ">{{ month.value }}</option>
                        </select>
                    </div>
                </div>
        
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Quincena</label>
                        <select class="custom-select" formControlName="fortnight">
                            <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 text-right">
                    <button type="button" [disabled]="isLoadingData" class="btn btn-outline-info" (click)="loadData()">
                        <i class="fas" [ngClass]="{ 'fa-search': !isLoadingData, 'fa-sync-alt fa-spin': isLoadingData }" ></i>
                        <span *ngIf="!isLoadingData"> Consultar</span>
                        <span *ngIf="isLoadingData" > Consultando...</span>
                    </button>

                    <button [disabled]="isGeneratingExcel" style="margin: 15px;" type="button" class="btn btn-outline-success" (click)="getExcel()">
                        <i [ngClass]="{ 'far fa-file-excel': !isGeneratingExcel, 'fas fa-sync-alt fa-spin': isGeneratingExcel }"></i>
                        <span *ngIf="!isGeneratingExcel"> Generar</span>
                        <span *ngIf="isGeneratingExcel" > Generando...</span>
                    </button>
                </div>
            </div>
        </form>

        <app-not-data *ngIf="!isLoadingData && movements.length === 0"></app-not-data>

        <app-loading-data *ngIf="isLoadingData"></app-loading-data>

        <div class="card items animated fadeIn fast" *ngIf="!isLoadingData && movements.length > 0">
            <fieldset>
                <legend>Totales <span *ngIf="detailedTemp  === 'true'" >({{ grandTotal | currency }})</span></legend>

                <ul class="item-list striped">
                    <li class="item item-list-header">
                        <div class="row">
                            <div class="item-col item-col-header item-col-header">
                                <div>
                                    <span>Tipo</span>
                                </div>
                                <div>
                                    <span>Movmimientos</span>
                                </div>
                                <div>
                                    <span>Total</span>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="item" *ngFor="let item of totals; let i = index"> 
                        <div class="item-row">
                            <div class="item-col item-col-item">
                                <div class="item-heading">Tipo</div>
                                <div> {{ item.tipo }} </div>
                            </div>
                            <div class="item-col item-col-item">
                                <div class="item-heading">Movmimientos</div>
                                <div> {{ item.movements | number }} </div>
                            </div>
                            <div class="item-col item-col-item">
                                <div class="item-heading">Total</div>
                                <div> {{ item.total | currency }} </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </fieldset>

            <fieldset>
                <legend>Movimientos <span *ngIf="detailedTemp  === 'true'" >({{ totalItems | number }})</span></legend>

                <div class="table-responsive">
                    <table class="table table-striped" *ngIf="!loadingData">
                        <thead>
                            <tr class="text-center">
                                <th scope="col">RFC</th>
                                <th scope="col">CURP</th>
                                <th scope="col">Nombre</th>
                                <th scope="col" *ngIf="detailedTemp  === 'true'" >Tipo</th>
                                <th scope="col" *ngFor="let item of header">Qna {{item}}</th>
                            </tr>
                        </thead>
        
                        <tbody>
                            <tr *ngFor="let item of movements | paginate: {itemsPerPage: this.itemsPerPage, currentPage: currentPage, totalItems: totalItems  }; let i = index">
                                <td>{{ item.rfc }}</td>
                                <td>{{ item.curp }}</td>
                                <td style="width: 450 !important;">{{ item.nombre }}</td>
                                <td *ngIf="detailedTemp === 'true'" >{{ item.tipo }}</td>
                                <td *ngIf="header.includes('1')" class="text-right" >{{ item.qna1 | currency }}</td>
                                <td *ngIf="header.includes('2')" class="text-right" >{{ item.qna2 | currency }}</td>
                                <td *ngIf="header.includes('3')" class="text-right" >{{ item.qna3 | currency }}</td>
                                <td *ngIf="header.includes('4')" class="text-right" >{{ item.qna4 | currency }}</td>
                                <td *ngIf="header.includes('5')" class="text-right" >{{ item.qna5 | currency }}</td>
                                <td *ngIf="header.includes('6')" class="text-right" >{{ item.qna6 | currency }}</td>
                                <td *ngIf="header.includes('7')" class="text-right" >{{ item.qna7 | currency }}</td>
                                <td *ngIf="header.includes('8')" class="text-right" >{{ item.qna8 | currency }}</td>
                                <td *ngIf="header.includes('9')" class="text-right" >{{ item.qna9 | currency }}</td>
                                <td *ngIf="header.includes('10')" class="text-right" >{{ item.qna10 | currency }}</td>
                                <td *ngIf="header.includes('11')" class="text-right" >{{ item.qna11 | currency }}</td>
                                <td *ngIf="header.includes('12')" class="text-right" >{{ item.qna12 | currency }}</td>
                                <td *ngIf="header.includes('13')" class="text-right" >{{ item.qna13 | currency }}</td>
                                <td *ngIf="header.includes('14')" class="text-right" >{{ item.qna14 | currency }}</td>
                                <td *ngIf="header.includes('15')" class="text-right" >{{ item.qna15 | currency }}</td>
                                <td *ngIf="header.includes('16')" class="text-right" >{{ item.qna16 | currency }}</td>
                                <td *ngIf="header.includes('17')" class="text-right" >{{ item.qna17 | currency }}</td>
                                <td *ngIf="header.includes('18')" class="text-right" >{{ item.qna18 | currency }}</td>
                                <td *ngIf="header.includes('19')" class="text-right" >{{ item.qna19 | currency }}</td>
                                <td *ngIf="header.includes('20')" class="text-right" >{{ item.qna20 | currency }}</td>
                                <td *ngIf="header.includes('21')" class="text-right" >{{ item.qna21 | currency }}</td>
                                <td *ngIf="header.includes('22')" class="text-right" >{{ item.qna22 | currency }}</td>
                                <td *ngIf="header.includes('23')" class="text-right" >{{ item.qna23 | currency }}</td>
                                <td *ngIf="header.includes('24')" class="text-right" >{{ item.qna24 | currency }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </fieldset>
        </div>
        
        <pagination-controls nextLabel="Siguiente" previousLabel="Anterior" (pageChange)="onPageChange($event)"></pagination-controls> 

    </div>
</article>