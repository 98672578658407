import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TeacherService } from '../../administration/teachers/services/teacher.service';
import { TeacherInterface } from '../../administration/teachers/interface/teacher.interface';
import { SavingInterface } from '../../administration/savings/interfeces/saving.interface';
import { LoanInterface } from '../../administration/loans/interfaces/loan.interface';
import { PaymentInterface } from '../../administration/payments/interfaces/payment.interface';

@Component({
  selector: 'app-teacher-global-details',
  templateUrl: './teacher-global-details.component.html',
  styles: []
})
export class TeacherGlobalDetailsComponent implements OnInit {
  message: string;
  loadingData = false;
  savings: SavingInterface[];
  pathReturn = '/reports/teachers-global';
  loans: LoanInterface[];
  payments: PaymentInterface[];
  percentageInterest = 0.00208;
  amount = 0;
  amountInterests = 0;
  teacher: TeacherInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: '00000000-0000-0000-0000-000000000000',
    date: new Date(),
    active: true,
    rfc: '',
    name: '',
    street: '',
    number: '',
    colony: '',
    zipCode: 0,
    locality: '',
    email: '',
    admission: '',
    birthdate: '',
    contributionId: '00000000-0000-0000-0000-000000000000',
    gender: '',
    identifier: 0,
    amountSavings: 0,
    lastStatus: 'Activo',
    applied: true,
    credit: 0,
    lastUpdated: new Date,
    type: '',
    fortnight: '',
    userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
    regionId: '00000000-0000-0000-0000-000000000000',
    clabe: '',
    curp: '',
    bankId: '00000000-0000-0000-0000-000000000000',
    member: true,
    amountSavingsFortnight: 0,
    document: 0,
    documentNumber: '',
    NumberOfLoans: 0,
    bank: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: false,
      lastUpdated: new Date(),
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      name: '',
      code: ''
    },
    contribution: {},
    teacherStatus: [],
    beneficiaries: [],
    teacherPhones: [],
    contributionPercentages: [],
    loans: [],
    savings: [],
    financialliquidity: [],
    payments: [],
    supports: [],
    lowStaffing: [],
    itIsSupplier: false
  };

  constructor( private activatedRoute: ActivatedRoute,
    private router: Router,
    private teacherService: TeacherService ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.loadingData = true;
      if (params['id']) {
        this.teacherService.getById(params['id']).subscribe(
          teacher => {
            this.teacher = Object.assign({}, teacher);
            this.savings = this.teacher.savings
              .sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 )
              .filter(s => s.teacherStatus.active === true);
            this.teacher.financialliquidity.sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 );
            this.loans = this.teacher.loans.sort((a, b) => ( parseInt(a.draw.name, 0) > parseInt(b.draw.name, 0)) ? 1 : -1 );
            this.payments = this.teacher.payments.sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 );
            this.calculateAmount(this.savings, this.percentageInterest);
            this.loadingData = false;
          },
          error => this.setErrorMessage(error)
        );
      } else {
        this.router.navigate(['reports/teachers-global']);
      }
    });
  }

  setErrorMessage( error ) {
    console.log( error );
    if (error && error.error) {
      this.message = error.error;
      this.loadingData = false;
    }
  }

  changeSavings( checked: boolean ) {
    if ( checked ) {
      this.savings = this.teacher.savings
              .sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 );
    } else {
      this.savings = this.teacher.savings
              .sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 )
              .filter(s => s.teacherStatus.active === true);
    }
    this.calculateAmount(this.savings, this.percentageInterest);
  }

  changeLoans( checked: boolean ) {
    if ( checked ) {
      this.loans = this.teacher.loans
                    .sort((a, b) => ( parseInt(a.draw.name, 0) > parseInt(b.draw.name, 0)) ? 1 : -1 );
    } else {
      this.loans = this.loans = this.teacher.loans
              .sort((a, b) => ( parseInt(a.draw.name, 0) > parseInt(b.draw.name, 0)) ? 1 : -1 )
              .filter(l => l.teacherStatus.active === true);
    }
  }

  changePayments( checked: boolean ) {
    if ( checked ) {
      this.payments = this.teacher.payments
                      .sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 );
    } else {
      this.payments = this.teacher.payments
                      .sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 )
                      .filter(p => p.loan !== null);
      if ( this.payments.length > 0 ) {
        this.payments = this.teacher.payments
                      .sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 )
                      .filter(p => p.loan.teacherStatus.active === true);
      }
    }
  }

  calculateSavings( percentage: number) {
    this.percentageInterest = percentage;
    this.calculateAmount(this.savings, this.percentageInterest);
  }

  calculateAmount(data: SavingInterface[], percentage: number) {
    this.amount = 0;
    this.amountInterests = 0;
    let interestWithImport = 0;

    data.forEach(year => {
      let interestPeritem = 0;
      let amountPeritem = 0;
      const arrayYear = Object.keys(year).map(function(key) {
        return [String(key), year[key]];
      });

      for (let index = 0; index < arrayYear.length; index++) {
        if ( arrayYear[index][0].slice(0, 9).toUpperCase() === 'FORTNIGHT' ) {
          if ( arrayYear[index][1] !== 0 ) {
            const interestFortnight = (interestWithImport + arrayYear[index][1]) * percentage;
            interestPeritem += interestFortnight;
            interestWithImport += arrayYear[index][1] + interestFortnight;
            amountPeritem += arrayYear[index][1];
          }
        }
      }
      year.folio = parseFloat(interestPeritem.toFixed(2)).toString();
      this.amountInterests += parseFloat(year.folio);
      this.amount += amountPeritem;
    });
  }

}
