import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from './services/payment.service';
import { PaymentInterface } from './interfaces/payment.interface';
import { AccountService } from '../../account/login/services/account.service';

@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styles: []
})
export class PaymentDetailComponent implements OnInit {
  forma: UntypedFormGroup;
  message: string;
  gettingData = false;
  searchValue = '';
  amount = 0;
  payment: PaymentInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    teacherId: '',
    year: new Date().getFullYear(),
    lastUpdated : new Date(),
    userIdLastUpdated: this.accountService.getUserId(),
    fortnight1: 0,
    fortnight2: 0,
    fortnight3: 0,
    fortnight4: 0,
    fortnight5: 0,
    fortnight6: 0,
    fortnight7: 0,
    fortnight8: 0,
    fortnight9: 0,
    fortnight10: 0,
    fortnight11: 0,
    fortnight12: 0,
    fortnight13: 0,
    fortnight14: 0,
    fortnight15: 0,
    fortnight16: 0,
    fortnight17: 0,
    fortnight18: 0,
    fortnight19: 0,
    fortnight20: 0,
    fortnight21: 0,
    fortnight22: 0,
    fortnight23: 0,
    fortnight24: 0,
    type: 'Retención',
    folio: '',
    amountSaving: 0,
    interest: 0,
    condoneInterest: 0,
    financialProducts: 0,
    lifeInsurance: 0,
    condoneLifeInsurance: 0,
    wayToPay: '',
    movementDate: '',
    loanId: '00000000-0000-0000-0000-000000000000',
    loan: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      regionId: '00000000-0000-0000-0000-000000000000',
      bankId: '00000000-0000-0000-0000-000000000000',
      teacherStatusId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: true,
      drawId: '',
      teacherId: '',
      loanParameterId: '',
      prefix: '',
      individualAccount: '',
      checkNumber: '',
      amount: 0,
      discount: 0,
      interests: 0,
      comment: '',
      loanNumber: '',
      fortnightStart: '',
      fortnightEnd: '',
      status: '',
      openingBonus: 0,
      remainingFortnightly: 0,
      warranty: 0,
      applied: false,
      haveFinancialLiquidity: false,
      account: '',
      movementType: '',
      lastUpdated: new Date,
      userIdLastUpdated: this.accountService.getUserId(),
      draw: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: true,
        name: 'N/A',
        openDate: new Date(),
        closeDate: new Date(),
        status: '',
        lastUpdated: new Date,
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        loans: []
      },
      teacher: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: this.accountService.getUserId(),
        date: new Date(),
        active: true,
        regionId: '00000000-0000-0000-0000-000000000000',
        rfc: '',
        name: '',
        street: '',
        number: '',
        colony: '',
        zipCode: 0,
        locality: '',
        email: '',
        admission: '',
        lastStatus: 'Activo',
        birthdate: '',
        applied: true,
        contributionId: '00000000-0000-0000-0000-000000000000',
        gender: '',
        lastUpdated: new Date,
        userIdLastUpdated: this.accountService.getUserId(),
        identifier: 0,
        amountSavings: 0,
        credit: 0,
        type: '',
        fortnight: '',
        clabe: '',
        curp: '',
        bankId: '00000000-0000-0000-0000-000000000000',
        member: false,
        amountSavingsFortnight: 0,
        document: 0,
        documentNumber: '',
        NumberOfLoans: 0,
        bank: {
          id: '00000000-0000-0000-0000-000000000000',
          userId: '00000000-0000-0000-0000-000000000000',
          date: new Date(),
          active: false,
          lastUpdated: new Date(),
          userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
          name: '',
          code: ''
        },
        contribution: {},
        teacherStatus: [],
        beneficiaries: [],
        teacherPhones: [],
        contributionPercentages: [],
        loans: [],
        savings: [],
        financialliquidity: [],
        payments: [],
        supports: [],
        lowStaffing: [],
        itIsSupplier: false
      },
      region: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date,
        active: true,
        lastUpdated: new Date,
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: 'N/A'
      },
      loanParameter: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        active: true,
        amount: 0,
        prefix: '',
        discount: 0,
        interests: 0,
        totalPerceptions: 0,
        saving: 0,
        guaranteedPercentage: 0,
        openingBonus: 0,
        fortnightId: '',
        fortnight: {
          id: '00000000-0000-0000-0000-000000000000',
          userId: '00000000-0000-0000-0000-000000000000',
          date: new Date(),
          active: true,
          numberFortnight: 0
        },
        fortnightCapital: 0,
        fortnightIVA: 0,
        fortnightInterest: 0,
        fortnightLifeInsurance: 0,
        iva: 0,
        lifeInsurance: 0,
        type: '',
        teachers: '',
        accountingConcept: ''
      },
      bank: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: false,
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: '',
        code: ''
      },
      teacherStatus: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: this.accountService.getUserId(),
        date: new Date,
        active: true,
        name: 'Activo',
        percentageSaving: 0,
        lastUpdated: new Date,
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000'
      },
      acceptanceDate: new Date(),
      protectionPremium: 0,
      block: 0,
      questions: [],
      payments: []
    },
    teacher: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      lastUpdated: new Date(),
      userIdLastUpdated: this.accountService.getUserId(),
      regionId: '00000000-0000-0000-0000-000000000000',
      rfc: '',
      name: '',
      street: '',
      number: '',
      colony: '',
      zipCode: 0,
      locality: '',
      applied: true,
      email: '',
      lastStatus: 'Activo',
      admission: '',
      birthdate: '',
      contributionId: '00000000-0000-0000-0000-000000000000',
      gender: '',
      identifier: 0,
      amountSavings: 0,
      credit: 0,
      type: '',
      fortnight: '',
      clabe: '',
      curp: '',
      bankId: '00000000-0000-0000-0000-000000000000',
      member: false,
      amountSavingsFortnight: 0,
      document: 0,
      documentNumber: '',
      NumberOfLoans: 0,
      bank: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: false,
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: '',
        code: ''
      },
      contribution: {},
      teacherStatus: [],
      beneficiaries: [],
      teacherPhones: [],
      contributionPercentages: [],
      loans: [],
      savings: [],
      financialliquidity: [],
      payments: [],
      supports: [],
      lowStaffing: [],
      itIsSupplier: false
    }
  };

  constructor( private activatedRoute: ActivatedRoute, private formBuilder: UntypedFormBuilder,
    private paymentService: PaymentService, public accountService: AccountService ) { }

  ngOnInit() {
    this.gettingData = true;
    this.buildForm();

    this.activatedRoute.params.subscribe(params => {
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }
      this.paymentService.getById(params['id'])
        .subscribe(payment => {
          this.payment = payment;
          this.forma.setValue(this.payment);
          this.calculateAmount();
          this.gettingData = false;
      }, error => this.setErrorMessage(error));
    }, error => this.setErrorMessage(error));

  }

  buildForm(): void {
    this.forma = this.formBuilder.group({
      id: [''],
      userId: [''],
      date: [''],
      active: [''],
      teacherId: [''],
      lastUpdated: [''],
      userIdLastUpdated: [''],
      year: ['', [Validators.required]],
      fortnight1: ['', [Validators.required]],
      fortnight2: ['', [Validators.required]],
      fortnight3: ['', [Validators.required]],
      fortnight4: ['', [Validators.required]],
      fortnight5: ['', [Validators.required]],
      fortnight6: ['', [Validators.required]],
      fortnight7: ['', [Validators.required]],
      fortnight8: ['', [Validators.required]],
      fortnight9: ['', [Validators.required]],
      fortnight10: ['', [Validators.required]],
      fortnight11: ['', [Validators.required]],
      fortnight12: ['', [Validators.required]],
      fortnight13: ['', [Validators.required]],
      fortnight14: ['', [Validators.required]],
      fortnight15: ['', [Validators.required]],
      fortnight16: ['', [Validators.required]],
      fortnight17: ['', [Validators.required]],
      fortnight18: ['', [Validators.required]],
      fortnight19: ['', [Validators.required]],
      fortnight20: ['', [Validators.required]],
      fortnight21: ['', [Validators.required]],
      fortnight22: ['', [Validators.required]],
      fortnight23: ['', [Validators.required]],
      fortnight24: ['', [Validators.required]],
      loanId: [''],
      teacher: [''],
      type: [''],
      amountSaving: [''],
      interest: [''],
      folio: [''],
      condoneInterest: [''],
      financialProducts: [''],
      movementDate: [''],
      typePayroll: [''],
      lifeInsurance: [''],
      condoneLifeInsurance: [''],
      wayToPay: [''],
      loan: ['']
    });
  }

  setErrorMessage( error: any ) {
    if (error && error.error) {
      console.error(error);
      this.message = error.message;
      this.gettingData = false;
    }
  }

  calculateAmount(): void {
    let amount = 0;
    for (let index = 1; index <= 24; index++) {
      amount += this.forma.controls['fortnight' + index].value;
    }
    this.amount = amount;
  }

}
