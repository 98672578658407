<article class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/banks/collections']"
                    routerLinkActive="router-link-active">Cobranza</a></li>
            <li class="breadcrumb-item active" aria-current="page">Procesar</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Procesar</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">

        <form [formGroup]="forma">
            <div class="row form-group">
                <!-- <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 text-right pt-3" *ngIf="accountService.havePermission(applications, 'Cobranza Aplicar')">
                    <a class="btn btn-outline-success" [routerLink]="['/banks/collections/apply']">
                        <i class="fas fa-user-check"></i>
                        <span>Aplicar</span>
                    </a>
                </div>

                 <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 text-right pt-3" *ngIf="accountService.havePermission(applications, 'Cobranza Historial')">
                    <a class="btn btn-outline-primary" [routerLink]="['/banks/collections/history']">
                        <i class="fas fa-history"></i>
                        <span>Historial</span>
                    </a>
                </div> -->
            </div>
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Maestros</label>
                        <select class="custom-select" formControlName="teachers">
                            <option value="Media Superior">Media Superior</option>
                            <option value="Alta Jubilación">Alta Jubilación</option>
                            <option value="Básico Domiciliado">Básico Domiciliado</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Tipo</label>
                        <select class="custom-select" formControlName="type">
                            <option value="Ahorros">Ahorros</option>
                            <option value="Abonos">Abonos</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Año</label>
                        <select class="custom-select" formControlName="year">
                            <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Quincena</label>
                        <select class="custom-select" formControlName="fortnight">
                            <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group" [ngClass]="{ 'has-success': forma.controls['reference'].touched }">
                        <label class="control-label">Referencia</label>
                        <input type="text" class="form-control underlined" maxlength="40" formControlName="reference">
                        <span
                            *ngIf="forma.controls['reference'].touched && forma.controls['reference'].errors?.required"
                            class="has-error ">
                            El campo es requerido.
                        </span>
                        <span
                            *ngIf="forma.controls['reference'].touched && forma.controls['reference'].errors?.minlength"
                            class="has-error">
                            El campo comentario debe tener {{
                            forma.controls['reference'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>
            </div>
        </form>

        <div class="form-group row">
            <div class="col-12 text-center">
                <button [disabled]="isLoadingData || !forma.valid" type="button" class="btn btn-outline-success"
                    (click)="handlerClick()">
                    <i [ngClass]="{ 'far fa-save': !isLoadingData, 'fas fa-sync-alt fa-spin': isLoadingData }"></i>
                    <span *ngIf="!isLoadingData"> Procesar</span>
                    <span *ngIf="isLoadingData"> Procesando...</span>
                </button>
            </div>
        </div>

        <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>
    </div>
</article>