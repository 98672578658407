import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../../../services/helper.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ExcelService } from '../../../../services/excel.service';
import { LowstaffingMovementsService } from '../services/lowstaffing-movements.service';

@Component({
  selector: 'app-lowstaffing-movements',
  templateUrl: './lowstaffing-movements.component.html',
  styleUrls: ['./lowstaffing-movements.component.css']
})
export class LowstaffingMovementsComponent implements OnInit {
  forma: FormGroup;
  currentPage = 1;
  itemsPerPage = 15;
  years: number[] = [];
  fortnights: string[] = [];
  months: any[] = [
    {key: 0, value: 'Todo', fortnights: ['1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24']},
    {key: 1, value: 'Enero', fortnights: ['1', '2', '1,2']},
    {key: 2, value: 'Febrero', fortnights: ['3', '4', '3,4']},
    {key: 3, value: 'Marzo', fortnights: ['5', '6','5,6']},
    {key: 4, value: 'Abril', fortnights: ['7', '8', '7,8']},
    {key: 5, value: 'Mayo', fortnights: ['9', '10', '9,10']},
    {key: 6, value: 'Junio', fortnights: ['11', '12', '11,12']},
    {key: 7, value: 'Julio', fortnights: ['13', '14', '13,14']},
    {key: 8, value: 'Agosto', fortnights: ['15', '16', '15,16']},
    {key: 9, value: 'Septiembre', fortnights: ['17', '18','17,18']},
    {key: 10, value: 'Octubre', fortnights: ['19', '20', '19,20']},
    {key: 11, value: 'Noviembre', fortnights: ['21', '22', '21,22']},
    {key: 12, value: 'Diciembre', fortnights: ['23', '24', '23,24']},
  ];
  isLoadingData = true;
  isGeneratingExcel = false;
  movements: any[] = [];
  totals: any[] = [];
  totalItems = 0;
  
  constructor(
    private lowstaffingMovementsService: LowstaffingMovementsService,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private excelService: ExcelService
  ) {}

  ngOnInit(): void {
    this.forma = this.formBuilder.group({
      inicial: [`${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-01`],
      final: [`${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`]
    });
    this.loadData();
  }

  onPageChange(page): void {
    this.currentPage = page;
    this.loadData();
  }

  getExcel(): void {
    this.isGeneratingExcel = true;
    this.lowstaffingMovementsService.get(this.inicio, this.final, 1, this.totalItems)
    .subscribe(data => {
      this.excelService.exportAsExcelFile(data.movements, `Movimientos_bajas_`);
      this.isGeneratingExcel = !this.isGeneratingExcel;
    },
    (error) => this.helperService.setErrorMessage(error));
  }

  loadData(): void {
    this.isLoadingData = true;
    this.lowstaffingMovementsService.get(this.inicio, this.final, this.currentPage, this.itemsPerPage)
    .subscribe(data => {
      console.log('data', data)
      this.movements = data.movements;
      this.totals = data.totals;
      this.totalItems = this.totals.reduce((sum, current) => sum + current.movimientos, 0);
      this.isLoadingData = !this.isLoadingData;
    },
    (error) => this.helperService.setErrorMessage(error));
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  get inicio(): string {
    return this.forma.get('inicial').value;
  }

  get final(): string {
    return this.forma.get('final').value;
  }

}
