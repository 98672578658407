import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../account/login/services/account.service';
import { TeacherInterface } from './interface/teacher.interface';
import { TeacherService } from './services/teacher.service';

@Component({
  selector: 'app-teacher-details',
  templateUrl: './teacher-details.component.html',
  styles: [
  ]
})
export class TeacherDetailsComponent implements OnInit {
  loadingData = false;
  searchValue = '';
  message: string;
  region = '';
  showBankInformation = false;
  teacher: TeacherInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    rfc: '',
    name: '',
    street: '',
    number: '',
    colony: '',
    zipCode: 0,
    locality: '',
    email: '',
    admission: '',
    birthdate: '',
    contributionId: '00000000-0000-0000-0000-000000000000',
    gender: '',
    identifier: 0,
    amountSavings: 0,
    lastStatus: 'Activo',
    applied: true,
    credit: 0,
    lastUpdated: new Date,
    type: '',
    fortnight: '',
    userIdLastUpdated: this.accountService.getUserId(),
    regionId: '00000000-0000-0000-0000-000000000000',
    clabe: '',
    curp: '',
    bankId: '00000000-0000-0000-0000-000000000000',
    member: true,
    amountSavingsFortnight: 0,
    document: 0,
    documentNumber: '',
    NumberOfLoans: 0,
    bank: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: false,
      lastUpdated: new Date(),
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      name: '',
      code: ''
    },
    contribution: {},
    teacherStatus: [],
    beneficiaries: [],
    teacherPhones: [],
    contributionPercentages: [],
    loans: [],
    savings: [],
    financialliquidity: [],
    payments: [],
    supports: [],
    lowStaffing: [],
    itIsSupplier: false
  };

  constructor( private teacherService: TeacherService,
    public accountService: AccountService,
    private activatedRoute: ActivatedRoute ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.loadingData = true;

      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }

      if (params['id']) {
        this.teacher.id = params['id'];
        this.teacherService.getById(params['id']).subscribe(
          teacher => {
            this.teacher = Object.assign({}, teacher);
            this.region = teacher.region.name;
            if ( this.teacher.admission ) {
              this.teacher.admission = this.teacher.admission.substring(0, 10);
            }
            if ( this.teacher.type !== 'Básico' && this.teacher.type !== 'Reingreso' ) {
              this.showBankInformation = true;
            }
            this.teacher.birthdate = this.teacher.birthdate.substring(0, 10);
            this.loadingData = false;
          },
          error => this.errorMessage(error)
        );
      }
    });
  }

  errorMessage( error ) {
    console.log(error);
    this.message = error.message;
    this.loadingData = false;
  }

  getDocument( document: number ): string {
    switch ( document ) {
      case 1:
        return 'IFE'
      case 2:
        return 'Pasaporte'
      case 3:
        return 'Otro'
      default:
        return ''
    }
  }

}
