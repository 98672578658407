<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Movimientos</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Bajas</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">

        <form [formGroup]="forma">
            <div class="row form-group">
        
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Inicio</label>
                        <input type="date" class="form-control" formControlName="inicial" >
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Fin</label>
                        <input type="date" class="form-control" formControlName="final" >
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 text-right">
                    <button type="button" [disabled]="isLoadingData" class="btn btn-outline-info" (click)="loadData()">
                        <i class="fas" [ngClass]="{ 'fa-search': !isLoadingData, 'fa-sync-alt fa-spin': isLoadingData }" ></i>
                        <span *ngIf="!isLoadingData"> Consultar</span>
                        <span *ngIf="isLoadingData" > Consultando...</span>
                    </button>

                    <button [disabled]="isGeneratingExcel" style="margin: 15px;" type="button" class="btn btn-outline-success" (click)="getExcel()">
                        <i [ngClass]="{ 'far fa-file-excel': !isGeneratingExcel, 'fas fa-sync-alt fa-spin': isGeneratingExcel }"></i>
                        <span *ngIf="!isGeneratingExcel"> Generar</span>
                        <span *ngIf="isGeneratingExcel" > Generando...</span>
                    </button>
                </div>
            </div>
        </form>

        <app-not-data *ngIf="!isLoadingData && movements.length === 0"></app-not-data>

        <app-loading-data *ngIf="isLoadingData"></app-loading-data>

        <div class="card items animated fadeIn fast" *ngIf="!isLoadingData && movements.length > 0">
            <fieldset>
                <legend>Totales</legend>

                <ul class="item-list striped">
                    <li class="item item-list-header">
                        <div class="row">
                            <div class="item-col item-col-header item-col-header">
                                <div>
                                    <span>Forma de pago</span>
                                </div>
                                <div>
                                    <span>Pago</span>
                                </div>
                                <div>
                                    <span>Ahorro</span>
                                </div>
                                <div>
                                    <span>Interes</span>
                                </div>
                                <div>
                                    <span>Crédito</span>
                                </div>
                                <div>
                                    <span>Interes</span>
                                </div>
                                <div>
                                    <span>Prima</span>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="item" *ngFor="let item of totals; let i = index"> 
                        <div class="item-row">
                            <div class="item-col item-col-item">
                                <div class="item-heading">Forma de pago</div>
                                <div> {{ item.formaPago }} </div>
                            </div>
                            <div class="item-col item-col-item">
                                <div class="item-heading">Pago</div>
                                <div> {{ item.importe | number }} </div>
                            </div>
                            <div class="item-col item-col-item">
                                <div class="item-heading">Ahorro</div>
                                <div> {{ item.ahorro | currency }} </div>
                            </div>
                            <div class="item-col item-col-item">
                                <div class="item-heading">Interes</div>
                                <div> {{ item.interes | currency }} </div>
                            </div>
                            <div class="item-col item-col-item">
                                <div class="item-heading">Crédito</div>
                                <div> {{ item.credito | currency }} </div>
                            </div>
                            <div class="item-col item-col-item">
                                <div class="item-heading">Interes</div>
                                <div> {{ item.interesCredito | currency }} </div>
                            </div>
                            <div class="item-col item-col-item">
                                <div class="item-heading">Prima</div>
                                <div> {{ item.prima | currency }} </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </fieldset>

            <fieldset>
                <legend>Movimientos ({{ totalItems | number }})</legend>

                <div class="table-responsive">
                    <table class="table table-striped" *ngIf="!loadingData">
                        <thead>
                            <tr class="text-center">
                                <th scope="col">RFC</th>
                                <th scope="col">CURP</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Forma de pago</th>
                                <th scope="col">Pago</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Ahorro</th>
                                <th scope="col">Interes</th>
                                <th scope="col">Crédito</th>
                                <th scope="col">Interes</th>
                                <th scope="col">Prima</th>
                            </tr>
                        </thead>
        
                        <tbody>
                            <tr *ngFor="let item of movements | paginate: {itemsPerPage: this.itemsPerPage, currentPage: currentPage, totalItems: totalItems  }; let i = index">
                                <td>{{ item.rfc }}</td>
                                <td>{{ item.curp }}</td>
                                <td style="width: 450 !important;">{{ item.nombre }}</td>
                                <td>{{ item.tipo }}</td>
                                <td>{{ item.formaPago }}</td>
                                <td>{{ item.importe | currency }}</td>
                                <td>{{ item.movimiento | date:'dd/MM/yyyy' }}</td>
                                <td>{{ item.ahorro | currency }}</td>
                                <td>{{ item.interes | currency }}</td>
                                <td>{{ item.credito | currency }}</td>
                                <td>{{ item.interesCredito | currency }}</td>
                                <td>{{ item.prima | currency }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </fieldset>
        </div>
        
        <pagination-controls nextLabel="Siguiente" previousLabel="Anterior" (pageChange)="onPageChange($event)"></pagination-controls> 

    </div>
</article>