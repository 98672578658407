import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LowStaffService } from './services/low-staff.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ExcelService } from '../../../services/excel.service';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';
declare let jsPDF;
declare var require: any;

@Component({
  selector: 'app-low-staff',
  templateUrl: './low-staff.component.html',
  styles: []
})
export class LowStaffComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  waytopay = 'Todo';
  start = '';
  end = '';
  forma: UntypedFormGroup;
  searchValue = '';
  working = false;
  exporting = false;
  loadingData = false;
  errorMessage = '';
  years: number[] = [];
  // tslint:disable-next-line:max-line-length
  fortnights: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];
  jsonArray: any[] = [];

  constructor( private activatedRoute: ActivatedRoute,
    private lowstaffService: LowStaffService, private router: Router,
    private formBuilder: UntypedFormBuilder,
    private excelService: ExcelService,
    private notificationService: NotificationService,
    private helperService: HelperService ) {
  }

  ngOnInit() {
    this.loadingData = true;
    this.activatedRoute.params.subscribe(params => {
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }
      if (params['waytopay']) {
        this.waytopay = params['waytopay'];
      }
      if (params['start']) {
        this.start = params['start'];
      }
      if (params['end']) {
        this.end = params['end'];
      }

      this.getYears();
      this.forma = this.formBuilder.group({
        year: [new Date().getFullYear()],
        fortnight: [this.getNumberFortnight()],
      });
      this.loadData();
    });
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while ( findingFortnight === true );
    if ( numberFortnight.toString().length === 1 ) {
      return `0${ numberFortnight.toString() }`;
    }

    return numberFortnight.toString();
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
    } else {
      date.setDate( date.getDate() + 14 );
    }
  }

  getYears(): void {
    for (let index = new Date().getFullYear() - 5; index < new Date().getFullYear() + 5; index++) {
      this.years.push(index);
    }
  }

  loadData() {
    this.lowstaffService.getPending( )
    .subscribe(data => {
      this.jsonArray = data;
      this.handlerSelectAll(true);
      this.loadingData = false;
    },
    error => this.helperService.setErrorMessage(error));
  }

  generateCheck() {
    this.working = true;
    this.lowstaffService.apply( `${ this.forma.controls['year'].value }-${ this.forma.controls['fortnight'].value }`, this.jsonArray.filter(l => l.active === true) )
      .subscribe(response => {
        this.generateDocument( response );
        this.router.navigate( ['administration/lowstaffing', this.searchValue] );
    }, err => this.helperService.setErrorMessage(err));
  }

  generateDocument(data: any[]): void {
    const doc = new jsPDF('portrait');
    // doc.addPage('letter', 'portrait');
    doc.setFont('arial');
    doc.setFontSize(9);
    let contador = 0;
    console.log(data);
    data.forEach(element => {
      if ( contador > 0 ) {
        doc.addPage();
      }

      const amount = element.amount;
      doc.text(`${ this.formatDate(new Date().getDate()) }/${ this.formatDate(new Date().getMonth()  + 1) }/${ new Date().getFullYear() }`,
      doc.internal.pageSize.width - 70, 23);
      // doc.setFont('arial');
      doc.setFontSize(9);
      doc.setFontStyle('bold');
      if ( element.type === 'Defunción' ) {
        doc.text(element.supportName.toUpperCase(), 45, 35);
      } else {
        doc.text(element.teacherName.toUpperCase().toUpperCase(), 45, 35);
      }

      doc.text(`${ this.numberFormat(amount.toString(), 2) }`, 175 , 35);
      doc.text(this.numberByLetter(amount), 30, 50);
      doc.text(`${ this.leftPad(element.checkNumber, 7, '0') }`, 90, 148);
      doc.setFontSize(14);
      doc.text('NO NEGOCIABLE', 10, 80);
      // doc.setFont('arial');
      doc.setFontSize(9);
      doc.setFontStyle('normal');
      doc.text(`${ this.numberFormat(amount.toString(), 2) }`, 150 , 168);
      if ( element.type === 'Defunción' ) {
        if ( element.comment === 'Ahorro' ) {
          doc.text('DEVOLUCIÓN DE AHORRO FOCAPS POR DEFUNCIÓN DE', 50, 168);
        } else {
          doc.text('PAGO DE APOYO GASTOS FUNERARIOS', 50, 168);
        }
        doc.text(`${ element.teacherName.toUpperCase() } ${ element.contribution } %`, 50, 173);
      } else if ( element.type === 'Jubilación' ) {
        doc.text('DEVOLUCIÓN DE APORTACIÓN AL FOCAPS', 50, 168);
        doc.text('POR BAJA JUBILATORIA', 50, 173);
      } else if ( element.type === 'Baja Voluntaria' ) {
        doc.text( `DEVOLUCIÓN DE APORTACIÓN AL FOCAPS`, 50, 168);
        doc.text( `POR BAJA VOLUNTARIA Q.${ element.comment }`, 50, 173);
      } else if(element.type === 'Renuncia') {
        doc.text( `DEVOLUCIÓN DE APORTACIÓN AL FOCAPS`, 50, 168);
        doc.text( `POR RENUNCIA Q.${ element.comment }`, 50, 173);
      }
      doc.text('DATOS PERSONALES: ', 10, 245);
      if ( element.type === 'Defunción' ) {
        doc.text(`NOMBRE: ${ element.supportName.toUpperCase() }`, 10, 250);
        doc.text(`RFC:`, 10, 255);
      } else {
        doc.text(`NOMBRE: ${ element.teacherName.toUpperCase() }`, 10, 250);
        doc.text(`RFC: ${ element.rfc.toUpperCase() }`, 10, 255);
      }
      doc.text(`TEL: ${ element.phone }`, 10, 260);
      doc.text(`REGIÓN: ${ element.region.toUpperCase() }`, 10, 265);
      contador++;
    });

    doc.save(`Bajas_${ this.forma.controls['year'].value }_${ this.forma.controls['fortnight'].value }.pdf`);
  }

  numberFormat( amount: any, decimals: number ): string {
    amount += '';
    amount = parseFloat(amount.replace(/[^0-9\.]/g, ''));
    decimals =  decimals || 0;
    if ( isNaN(amount) || amount === 0 ) {
      return parseFloat('0').toFixed(decimals);
    }

    amount = '' + amount.toFixed(decimals);

    const amountParts = amount.split('.'), regexp = /(\d+)(\d{3})/;
    while (regexp.test(amountParts[0])) {
      amountParts[0] = amountParts[0].replace(regexp, '$1' + ',' + '$2');
    }

    return amountParts.join('.');
  }

  showConfirm( id: string, lowstaff: any ) {
    this.notificationService.showConfirm(`¿Seguro que desea remover al maestro ${ lowstaff.rfc }?`, 'Remover maestro')
    .then((result: any) => {
      if(result.isConfirmed) {
        this.deleteItem(id, lowstaff);
      }
    });
  }

  deleteItem(id: string, lowstaff: any) {
    this.lowstaffService.delete(id).subscribe(
      () => {
        this.loadData();
        this.notificationService.toastConfirm(`Se elimino al maestro ${lowstaff.rfc}`)
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  numberByLetter(amount: number): string {
    const num2Let = require('numalet')();
    const number2letter = num2Let(amount).toString().substring(0, );
    let letter = number2letter.toString().substring(0, number2letter.indexOf(' MXN'));
    const arrQuantity = amount.toString().split('.');
    if ( arrQuantity.length > 1 ) {
      const partDecimal = ( arrQuantity[1].length === 1) ? `${ arrQuantity[1] }0` : arrQuantity[1];
      letter = number2letter.toString().substring(0, number2letter.indexOf(` ${ partDecimal }/100`));
      return `${ letter } PESOS ${ partDecimal }/100 M.N.`;
    }
    return `${ letter } PESOS 00/100 M.N.`;
  }

  leftPad(str: string, len: number, ch= '.'): string {
    len = len - str.length + 1;
    return len > 0 ?
      new Array(len).join(ch) + str : str;
  }

  getExcel(): void {
    const newData = this.jsonArray.map(d => ({
      Nombre: d.name,
      RFC: d.rfc,
      Ahorro: d.savings,
      Interes: d.interest,
      Prestamo: d.credit,
      PrestamoInteres: d.creditInterest,
      Importe: d.amount,
      Tipo: d.type
    }));
    this.excelService.exportAsExcelFile(newData,
      `Bajas_Pendientes_`);
  }

  handlerChecbox(checked: boolean, item: any): void {
    item.active = checked;
  }

  handlerSelectAll( value: boolean): void {
    this.jsonArray.forEach(element => {
      element.active = value
    });
  }

  get selectedItem(): boolean {
    return this.jsonArray.some(item => item.active === true);
  }
}
