import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LowstaffingMovementsService {
  private apiURL = 'api/movements/lowstaffing';

  constructor(
    private http: HttpClient 
  ) { }

  get( inicio: string, final: string, currentPage: number = 1, itemsPerPage: number = 10 ) {
    return this.http.get<any>(`${this.apiURL}/${inicio}/${final}/${currentPage}/${itemsPerPage}`);
  }
  
}
