import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { CollectionService } from './services/collection.service';
import { LoanInterface } from '../../administration/loans/interfaces/loan.interface';
import { TeacherService } from '../../administration/teachers/services/teacher.service';
import { TeacherInterface } from '../../administration/teachers/interface/teacher.interface';
import { AccountService } from '../../account/login/services/account.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styles: [],
})
export class CollectionComponent implements OnInit {
  forma: FormGroup;
  errorMessage: string;
  isLoadingData = false;
  numberCharacters = 166;
  years: number[] = [];
  applications: ApplicationInterface[];
  fortnights: number[] = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
  ];

  constructor(
    private collectionService: CollectionService,
    private formBuilder: UntypedFormBuilder,
    private teacherService: TeacherService,
    public accountService: AccountService
  ) {

  }

  ngOnInit() {
    this.forma = this.formBuilder.group({
      teachers: ['Media Superior'],
      type: ['Ahorros'],
      year: [new Date().getFullYear()],
      fortnight: [this.getNumberFortnight()],
      reference: ['', [Validators.required, Validators.maxLength(40)]],
    });
    this.getYears();
    this.accountService.getApplications('banks', 'collections').subscribe(
      (applications) => {
        this.applications = applications;

      },
      (error) => this.setErrorMessage(error)
    );
  }

  getYears(): void {
    for (
      let index = new Date().getFullYear() - 5;
      index < new Date().getFullYear() + 5;
      index++
    ) {
      this.years.push(index);
    }
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date(new Date().getFullYear(), 0, 15);
    do {
      if (date >= new Date()) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while (findingFortnight === true);
    return numberFortnight.toString();
  }

  getDateFortnight(date: Date): void {
    if (date.getDate() === 15) {
      date.setMonth(date.getMonth() + 1);
      date.setDate(1);
    } else {
      date.setDate(date.getDate() + 14);
    }
  }

  handlerClick() {
    this.isLoadingData = true;
    if (this.forma.controls['type'].value === 'Abonos') {
      // this.collectionService.get(this.forma.controls['year'].value, this.forma.controls['fortnight'].value)
      this.collectionService
        .get(this.forma.controls['teachers'].value)
        .subscribe(
          (data: Array<LoanInterface>) => {
            // tslint:disable-next-line:max-line-length
            this.descargarArchivo(
              this.generarTexto(data),
              `${
                this.forma.controls['reference'].value
              }${new Date().getTime()}.pag`
            );
          },
          (error) => this.setErrorMessage(error)
        );
    } else if (this.forma.controls['type'].value === 'Ahorros') {
      this.teacherService.getCollectionData( this.forma.controls['year'].value, this.forma.controls['fortnight'].value, this.forma.controls['teachers'].value)
      .subscribe(
        (data: Array<TeacherInterface>) => {
          this.descargarArchivo(this.generarTextoTeachers(data), `${this.forma.controls['reference'].value}${new Date().getTime()}.pag`);
        },
        (error) => this.setErrorMessage(error)
      );
    }
    this.isLoadingData = false;
  }

  formatNumber(value: number): string {
    const arrNumber = value.toString().split('.');
    if (arrNumber.length > 1) {
      if (arrNumber[1].length === 1) {
        return `${arrNumber[0]}${arrNumber[1]}0`;
      }
      return `${arrNumber[0]}${arrNumber[1]}`;
    }
    return `${arrNumber[0]}00`;
  }

  generarTexto(loans: Array<LoanInterface>) {
    const texto = [];
    texto.push(
      this.completeRight(this.getHeader(loans), this.numberCharacters - 1, ' ')
    );
    loans.forEach((element) => {
      let referencia = 'J' + element.teacher.curp;
      if (element.teacher.type === 'Media Superior') {
        referencia = 'M' + element.teacher.curp;
      }
      if (element.teacher.type === 'Básico Domiciliado') {
        referencia = 'F' + element.teacher.curp;
      }
      // tslint:disable-next-line:max-line-length
      texto.push(
        this.completeRight(
          `\nD${new Date().getFullYear().toString()}${this.completeLeft(
            (new Date().getMonth() + 1).toString(),
            2,
            '0'
          )}${this.completeLeft(
            new Date().getDate().toString(),
            2,
            '0'
          )}${this.completeRight(' ', 10, ' ')}${this.completeRight(
            referencia,
            40,
            ' '
          )}${this.completeRight(
            this.forma.controls['reference'].value,
            40,
            ' '
          )}${this.completeLeft(
            this.formatNumber(element.discount),
            15,
            '0'
          )}${this.completeLeft(
            element.teacher.bank.code.toString(),
            3,
            '0'
          )}40${element.teacher.clabe}1A00000000`,
          this.numberCharacters,
          ' '
        )
      );
    });
    return new Blob(texto, {
      type: 'text/plain',
    });
  }

  generarTextoTeachers(teachers: Array<TeacherInterface>) {
    const texto = [];
    texto.push(
      this.completeRight(
        this.getHeaderTeachers(teachers),
        this.numberCharacters - 1,
        ' '
      )
    );
    teachers.forEach((element) => {
      let referencia = 'J' + element.curp;
      if (element.type === 'Media Superior') {
        referencia = 'M' + element.curp;
      }
      if (element.type === 'Básico Domiciliado') {
        referencia = 'F' + element.curp;
      }
      // tslint:disable-next-line:max-line-length
      texto.push(
        this.completeRight(`\nD${new Date().getFullYear().toString()}${this.completeLeft((new Date().getMonth() + 1).toString(), 2, '0')}${this.completeLeft(new Date().getDate().toString(), 2, '0')}${this.completeRight(' ', 10, ' ')}${this.completeRight(referencia, 40,' ')}${this.completeRight(this.forma.controls['reference'].value, 40,' ')}${this.completeLeft(this.formatNumber(element.amountSavingsFortnight), 15, '0')}${this.completeLeft((element.bank) ? element.bank.code.toString() : '000', 3, '0')}40${element.clabe}1A00000000`,this.numberCharacters,' ')
      );
    });
    return new Blob(texto, {
      type: 'text/plain',
    });
  }

  descargarArchivo(contenidoEnBlob, nombreArchivo) {
    const reader: FileReader = new FileReader();
    reader.onload = function (event: Event) {
      const save = document.createElement('a');
      save.href = reader.result as string; // event.target.result;
      save.target = '_blank';
      save.download = nombreArchivo || 'archivo.pag';
      const clicEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      save.dispatchEvent(clicEvent);
      window.URL.revokeObjectURL(save.href);
    };
    reader.readAsDataURL(contenidoEnBlob);
  }

  setErrorMessage(error) {
    this.errorMessage = error;
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.isLoadingData = false;
    }
  }

  getHeaderTeachers(teachers: Array<TeacherInterface>): string {
    const grandTotal = teachers
      .reduce((sum, current) => sum + current.amountSavingsFortnight, 0)
      .toFixed(2)
      .replace('.', '');
    // tslint:disable-next-line:max-line-length
    return `HCP01205${new Date().getFullYear().toString()}${this.completeLeft(
      (new Date().getMonth() + 1).toString(),
      2,
      '0'
    )}${this.completeLeft(
      new Date().getDate().toString(),
      2,
      '0'
    )}01${this.completeLeft(
      teachers.length.toString(),
      6,
      '0'
    )}${this.completeLeft(grandTotal.toString(), 15, '0')}${this.completeLeft(
      teachers.length.toString(),
      6,
      '0'
    )}${this.completeLeft(
      grandTotal.toString(),
      15,
      '0'
    )}0000000000000000000000000000`;
  }

  getHeader(loans: Array<LoanInterface>): string {
    const amountLoans = loans
      .reduce((sum, current) => sum + current.discount, 0)
      .toFixed(2)
      .replace('.', '');
    // tslint:disable-next-line:max-line-length
    return `HCP01205${new Date().getFullYear().toString()}${this.completeLeft(
      (new Date().getMonth() + 1).toString(),
      2,
      '0'
    )}${this.completeLeft(
      new Date().getDate().toString(),
      2,
      '0'
    )}01${this.completeLeft(
      loans.length.toString(),
      6,
      '0'
    )}${this.completeLeft(amountLoans.toString(), 15, '0')}${this.completeLeft(
      loans.length.toString(),
      6,
      '0'
    )}${this.completeLeft(
      amountLoans.toString(),
      15,
      '0'
    )}0000000000000000000000000000`;
  }

  completeLeft(
    text: string,
    numberCharacters: number,
    character: string
  ): string {
    let textReturned = '';
    for (let index = text.length; index < numberCharacters; index++) {
      textReturned = `${character}${textReturned}`;
    }
    return textReturned + text;
  }

  completeRight(
    text: string,
    numberCharacters: number,
    character: string
  ): string {
    for (let index = text.length; index < numberCharacters; index++) {
      text = `${text}${character}`;
    }
    return text;
  }
}
