<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Movimientos</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Abonos</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">

        <form [formGroup]="forma">
            <div class="row form-group">
        
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Año</label>
                        <select class="custom-select" formControlName="year">
                            <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Mes</label>
                        <select class="custom-select" formControlName="month" (change)="onMonthChange()">
                            <option [value]="month.key" *ngFor="let month of months ">{{ month.value }}</option>
                        </select>
                    </div>
                </div>
        
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Quincena</label>
                        <select class="custom-select" formControlName="fortnight">
                            <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right">
                    <button type="button" [disabled]="isLoadingData" class="btn btn-outline-info" (click)="loadData()">
                        <i class="fas" [ngClass]="{ 'fa-search': !isLoadingData, 'fa-sync-alt fa-spin': isLoadingData }" ></i>
                        <span *ngIf="!isLoadingData"> Consultar</span>
                        <span *ngIf="isLoadingData" > Consultando...</span>
                    </button>

                    <button [disabled]="isGeneratingExcel" style="margin: 15px;" type="button" class="btn btn-outline-success" (click)="getExcel()">
                        <i [ngClass]="{ 'far fa-file-excel': !isGeneratingExcel, 'fas fa-sync-alt fa-spin': isGeneratingExcel }"></i>
                        <span *ngIf="!isGeneratingExcel"> Generar</span>
                        <span *ngIf="isGeneratingExcel" > Generando...</span>
                    </button>
                </div>
            </div>
        </form>

        <app-not-data *ngIf="!isLoadingData && movements.length === 0"></app-not-data>

        <app-loading-data *ngIf="isLoadingData"></app-loading-data>

        <div class="card items animated fadeIn fast" *ngIf="!isLoadingData && movements.length > 0">
            <fieldset>
                <legend>Totales ({{ grandTotal | currency }})</legend>

                <ul class="item-list striped">
                    <li class="item item-list-header">
                        <div class="row">
                            <div class="item-col item-col-header item-col-header">
                                <div>
                                    <span>Tipo</span>
                                </div>
                                <div>
                                    <span>Capital</span>
                                </div>
                                <div>
                                    <span>Interes</span>
                                </div>
                                <div>
                                    <span>Prima</span>
                                </div>
                                <div>
                                    <span>Movmimientos</span>
                                </div>
                                <div>
                                    <span>Total</span>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="item" *ngFor="let item of totals; let i = index"> 
                        <div class="item-row">
                            <div class="item-col item-col-item">
                                <div class="item-heading">Tipo</div>
                                <div> {{ item.type }} </div>
                            </div>
                            <div class="item-col item-col-item">
                                <div class="item-heading">Capital</div>
                                <div> {{ item.capital | currency }} </div>
                            </div>
                            <div class="item-col item-col-item">
                                <div class="item-heading">Interes</div>
                                <div> {{ item.interes + item.iva | currency }} </div>
                            </div>
                            <div class="item-col item-col-item">
                                <div class="item-heading">Prima</div>
                                <div> {{ item.prima | currency }} </div>
                            </div>
                            <div class="item-col item-col-item">
                                <div class="item-heading">Movmimientos</div>
                                <div> {{ item.movements | number }} </div>
                            </div>
                            <div class="item-col item-col-item">
                                <div class="item-heading">Total</div>
                                <div> {{ item.total | currency }} </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </fieldset>

            <fieldset>
                <legend>Movimientos ({{ totalItems | number }})</legend>

                <div class="table-responsive">
                    <table class="table table-striped" *ngIf="!loadingData">
                        <thead>
                            <tr class="text-center">
                                <th scope="col">RFC</th>
                                <th scope="col">CURP</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col"># Hoja</th>
                                <th scope="col" *ngFor="let item of header">Qna {{item}}</th>
                            </tr>
                        </thead>
        
                        <tbody>
                            <tr *ngFor="let item of movements | paginate: {itemsPerPage: this.itemsPerPage, currentPage: currentPage, totalItems: totalItems  }; let i = index">
                                <td>{{ item.rfc }}</td>
                                <td>{{ item.curp }}</td>
                                <td style="width: 450 !important;">{{ item.nombre }}</td>
                                <td>{{ item.tipo }}</td>
                                <td>{{ item.hoja }}</td>
                                <td *ngIf="header.includes('1')" class="text-right" >{{ item.qna1 | currency }} | {{ item.capital1 | currency }} | {{ item.interes1 | currency }} | {{ item.prima1 | currency }}</td>
                                <td *ngIf="header.includes('2')" class="text-right" >{{ item.qna2 | currency }} | {{ item.capital2 | currency }} | {{ item.interes2 | currency }} | {{ item.prima2 | currency }}</td>
                                <td *ngIf="header.includes('3')" class="text-right" >{{ item.qna3 | currency }} | {{ item.capital3 | currency }} | {{ item.interes3 | currency }} | {{ item.prima3 | currency }}</td>
                                <td *ngIf="header.includes('4')" class="text-right" >{{ item.qna4 | currency }} | {{ item.capital4 | currency }} | {{ item.interes4 | currency }} | {{ item.prima4 | currency }}</td>
                                <td *ngIf="header.includes('5')" class="text-right" >{{ item.qna5 | currency }} | {{ item.capital5 | currency }} | {{ item.interes5 | currency }} | {{ item.prima5 | currency }}</td>
                                <td *ngIf="header.includes('6')" class="text-right" >{{ item.qna6 | currency }} | {{ item.capital6 | currency }} | {{ item.interes6 | currency }} | {{ item.prima6 | currency }}</td>
                                <td *ngIf="header.includes('7')" class="text-right" >{{ item.qna7 | currency }} | {{ item.capital7 | currency }} | {{ item.interes7 | currency }} | {{ item.prima7 | currency }}</td>
                                <td *ngIf="header.includes('8')" class="text-right" >{{ item.qna8 | currency }} | {{ item.capital8 | currency }} | {{ item.interes8 | currency }} | {{ item.prima8 | currency }}</td>
                                <td *ngIf="header.includes('9')" class="text-right" >{{ item.qna9 | currency }} | {{ item.capital9 | currency }} | {{ item.interes9 | currency }} | {{ item.prima9 | currency }}</td>
                                <td *ngIf="header.includes('10')" class="text-right" >{{ item.qna10 | currency }} | {{ item.capital10 | currency }} | {{ item.interes10 | currency }} | {{ item.prima10 | currency }}</td>
                                <td *ngIf="header.includes('11')" class="text-right" >{{ item.qna11 | currency }} | {{ item.capital11 | currency }} | {{ item.interes11 | currency }} | {{ item.prima11 | currency }}</td>
                                <td *ngIf="header.includes('12')" class="text-right" >{{ item.qna12 | currency }} | {{ item.capital12 | currency }} | {{ item.interes12 | currency }} | {{ item.prima12 | currency }}</td>
                                <td *ngIf="header.includes('13')" class="text-right" >{{ item.qna13 | currency }} | {{ item.capital13 | currency }} | {{ item.interes13 | currency }} | {{ item.prima13 | currency }}</td>
                                <td *ngIf="header.includes('14')" class="text-right" >{{ item.qna14 | currency }} | {{ item.capital14 | currency }} | {{ item.interes14 | currency }} | {{ item.prima14 | currency }}</td>
                                <td *ngIf="header.includes('15')" class="text-right" >{{ item.qna15 | currency }} | {{ item.capital15 | currency }} | {{ item.interes15 | currency }} | {{ item.prima15 | currency }}</td>
                                <td *ngIf="header.includes('16')" class="text-right" >{{ item.qna16 | currency }} | {{ item.capital16 | currency }} | {{ item.interes16 | currency }} | {{ item.prima16 | currency }}</td>
                                <td *ngIf="header.includes('17')" class="text-right" >{{ item.qna17 | currency }} | {{ item.capital17 | currency }} | {{ item.interes17 | currency }} | {{ item.prima17 | currency }}</td>
                                <td *ngIf="header.includes('18')" class="text-right" >{{ item.qna18 | currency }} | {{ item.capital18 | currency }} | {{ item.interes18 | currency }} | {{ item.prima18 | currency }}</td>
                                <td *ngIf="header.includes('19')" class="text-right" >{{ item.qna19 | currency }} | {{ item.capital19 | currency }} | {{ item.interes19 | currency }} | {{ item.prima19 | currency }}</td>
                                <td *ngIf="header.includes('20')" class="text-right" >{{ item.qna20 | currency }} | {{ item.capital20 | currency }} | {{ item.interes20 | currency }} | {{ item.prima20 | currency }}</td>
                                <td *ngIf="header.includes('21')" class="text-right" >{{ item.qna21 | currency }} | {{ item.capital21 | currency }} | {{ item.interes21 | currency }} | {{ item.prima21 | currency }}</td>
                                <td *ngIf="header.includes('22')" class="text-right" >{{ item.qna22 | currency }} | {{ item.capital22 | currency }} | {{ item.interes22 | currency }} | {{ item.prima22 | currency }}</td>
                                <td *ngIf="header.includes('23')" class="text-right" >{{ item.qna23 | currency }} | {{ item.capital23 | currency }} | {{ item.interes23 | currency }} | {{ item.prima23 | currency }}</td>
                                <td *ngIf="header.includes('24')" class="text-right" >{{ item.qna24 | currency }} | {{ item.capital24 | currency }} | {{ item.interes24 | currency }} | {{ item.prima24 | currency }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </fieldset>
        </div>
        
        <pagination-controls nextLabel="Siguiente" previousLabel="Anterior" (pageChange)="onPageChange($event)"></pagination-controls> 

    </div>
</article>