<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <h3 class="title">
                        Bajas <small *ngIf="!loadingData">({{selectedItems}}  / {{ jsonArray.length }} ) 22</small>
                    </h3>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 text-right">
                    <button [disabled]="jsonArray.length === 0" type="button" class="btn btn-outline-success" (click)="getExcel()">
                        <i [ngClass]="{ 'far fa-file-excel': !exporting, 'fas fa-sync-alt fa-spin': exporting }"></i>
                        <span *ngIf="!exporting"> Exportar</span>
                        <span *ngIf="exporting" >
                            Procesando...
                        </span>
                    </button>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 text-right">
                    <button [disabled]="processing" type="button" class="btn btn-outline-success" (click)="exportData()">
                      <i [ngClass]="{ 'far fa-file-excel': !processing, 'fas fa-sync-alt fa-spin': processing } " ></i>
                      <span *ngIf="!processing "> Generar Poliza</span>
                      <span *ngIf="processing "> Generando Poliza</span>
                  </button>
                </div>
            </div>
        </div>
    </div>

    <div class="card items animated fadeIn fast" >
        <div class="row mt-2 ml-2">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 form-group " >
                <label class="control-label ">Tipo</label>
                <select name="tipo" class="form-control" [value]="tipo" [(ngModel)]="tipo" >
                    <Option value="Cheque">Cheque</Option>
                    <Option value="Transferencia">Transferencia</Option>
                </select>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 form-group " >
                <label class="control-label ">Filtro</label>
                <select name="tipo" class="form-control" [value]="filtro" [(ngModel)]="filtro" >
                    <Option value="1">Fecha</Option>
                    <Option value="2">Número Movimiento</Option>
                </select>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 form-group" >
                <label class="control-label ">Inicio</label>
                <input *ngIf="filtro === '1'" type="date" class="form-control" name="inicio" [value]="inicio" [(ngModel)]="inicio">
                <input *ngIf="filtro === '2'" type="number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" class="form-control" name="inicio" [value]="inicio" [(ngModel)]="inicio">
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 form-group " >
                <label class="control-label ">Al</label>
                <input *ngIf="filtro === '1'" type="date" class="form-control" name="fin" [value]="fin" [(ngModel)]="fin">
                <input *ngIf="filtro === '2'" type="number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" class="form-control" name="fin" [value]="fin" [(ngModel)]="fin">
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" >
                <button type="button" class="btn btn-outline-primary" (click)="search()">
                    Buscar
                </button>
            </div>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            {{ errorMessage }}
        </div>

        <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

        <app-loading-data *ngIf="loadingData"></app-loading-data>

        <div class="table-responsive" *ngIf="!loadingData && jsonArray.length > 0" >
            <table class="table table-striped table-hover">
                <thead>
                    <th scope="col">
                        R.F.C.
                    </th>
                    <th scope="col">
                        Nomobre
                    </th>
                    <th scope="col">
                        # Cheque
                    </th>
                    <th scope="col">
                        Fecha
                    </th>
                    <th scope="col">
                        Monto
                    </th>
                    <th scope="col">
                        Tipo
                    </th>
                    <th>

                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of jsonArray">
                        <td>{{ item.rfc }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.checkNumber }}</td>
                        <td>{{ item.lastUpdated | date: 'dd/MM/yyyy' }}</td>
                        <td>{{ item.amount | currency }}</td>
                        <td>{{ item.type }}</td>
                        <td>
                            <input (change)="setPermission( $event.target.checked, item )" type="checkbox" [checked]="item.active">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</article>
